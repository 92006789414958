// source: authzed/api/v0/core.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var validate_validate_pb = require('../../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.authzed.api.v0.DirectUserset', null, global);
goog.exportSymbol('proto.authzed.api.v0.ObjectAndRelation', null, global);
goog.exportSymbol('proto.authzed.api.v0.RelationReference', null, global);
goog.exportSymbol('proto.authzed.api.v0.RelationTuple', null, global);
goog.exportSymbol('proto.authzed.api.v0.RelationTupleTreeNode', null, global);
goog.exportSymbol('proto.authzed.api.v0.RelationTupleTreeNode.NodeTypeCase', null, global);
goog.exportSymbol('proto.authzed.api.v0.RelationTupleUpdate', null, global);
goog.exportSymbol('proto.authzed.api.v0.RelationTupleUpdate.Operation', null, global);
goog.exportSymbol('proto.authzed.api.v0.SetOperationUserset', null, global);
goog.exportSymbol('proto.authzed.api.v0.SetOperationUserset.Operation', null, global);
goog.exportSymbol('proto.authzed.api.v0.User', null, global);
goog.exportSymbol('proto.authzed.api.v0.User.UserOneofCase', null, global);
goog.exportSymbol('proto.authzed.api.v0.Zookie', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.RelationTuple = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authzed.api.v0.RelationTuple, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.RelationTuple.displayName = 'proto.authzed.api.v0.RelationTuple';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.ObjectAndRelation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authzed.api.v0.ObjectAndRelation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.ObjectAndRelation.displayName = 'proto.authzed.api.v0.ObjectAndRelation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.RelationReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authzed.api.v0.RelationReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.RelationReference.displayName = 'proto.authzed.api.v0.RelationReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.authzed.api.v0.User.oneofGroups_);
};
goog.inherits(proto.authzed.api.v0.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.User.displayName = 'proto.authzed.api.v0.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.Zookie = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authzed.api.v0.Zookie, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.Zookie.displayName = 'proto.authzed.api.v0.Zookie';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.RelationTupleUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authzed.api.v0.RelationTupleUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.RelationTupleUpdate.displayName = 'proto.authzed.api.v0.RelationTupleUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.RelationTupleTreeNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.authzed.api.v0.RelationTupleTreeNode.oneofGroups_);
};
goog.inherits(proto.authzed.api.v0.RelationTupleTreeNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.RelationTupleTreeNode.displayName = 'proto.authzed.api.v0.RelationTupleTreeNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.SetOperationUserset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.authzed.api.v0.SetOperationUserset.repeatedFields_, null);
};
goog.inherits(proto.authzed.api.v0.SetOperationUserset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.SetOperationUserset.displayName = 'proto.authzed.api.v0.SetOperationUserset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.DirectUserset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.authzed.api.v0.DirectUserset.repeatedFields_, null);
};
goog.inherits(proto.authzed.api.v0.DirectUserset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.DirectUserset.displayName = 'proto.authzed.api.v0.DirectUserset';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.RelationTuple.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.RelationTuple.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.RelationTuple} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.RelationTuple.toObject = function(includeInstance, msg) {
  var f, obj = {
    objectAndRelation: (f = msg.getObjectAndRelation()) && proto.authzed.api.v0.ObjectAndRelation.toObject(includeInstance, f),
    user: (f = msg.getUser()) && proto.authzed.api.v0.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.RelationTuple}
 */
proto.authzed.api.v0.RelationTuple.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.RelationTuple;
  return proto.authzed.api.v0.RelationTuple.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.RelationTuple} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.RelationTuple}
 */
proto.authzed.api.v0.RelationTuple.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.authzed.api.v0.ObjectAndRelation;
      reader.readMessage(value,proto.authzed.api.v0.ObjectAndRelation.deserializeBinaryFromReader);
      msg.setObjectAndRelation(value);
      break;
    case 2:
      var value = new proto.authzed.api.v0.User;
      reader.readMessage(value,proto.authzed.api.v0.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.RelationTuple.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.RelationTuple.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.RelationTuple} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.RelationTuple.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObjectAndRelation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.authzed.api.v0.ObjectAndRelation.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.authzed.api.v0.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional ObjectAndRelation object_and_relation = 1;
 * @return {?proto.authzed.api.v0.ObjectAndRelation}
 */
proto.authzed.api.v0.RelationTuple.prototype.getObjectAndRelation = function() {
  return /** @type{?proto.authzed.api.v0.ObjectAndRelation} */ (
    jspb.Message.getWrapperField(this, proto.authzed.api.v0.ObjectAndRelation, 1));
};


/**
 * @param {?proto.authzed.api.v0.ObjectAndRelation|undefined} value
 * @return {!proto.authzed.api.v0.RelationTuple} returns this
*/
proto.authzed.api.v0.RelationTuple.prototype.setObjectAndRelation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authzed.api.v0.RelationTuple} returns this
 */
proto.authzed.api.v0.RelationTuple.prototype.clearObjectAndRelation = function() {
  return this.setObjectAndRelation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authzed.api.v0.RelationTuple.prototype.hasObjectAndRelation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional User user = 2;
 * @return {?proto.authzed.api.v0.User}
 */
proto.authzed.api.v0.RelationTuple.prototype.getUser = function() {
  return /** @type{?proto.authzed.api.v0.User} */ (
    jspb.Message.getWrapperField(this, proto.authzed.api.v0.User, 2));
};


/**
 * @param {?proto.authzed.api.v0.User|undefined} value
 * @return {!proto.authzed.api.v0.RelationTuple} returns this
*/
proto.authzed.api.v0.RelationTuple.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authzed.api.v0.RelationTuple} returns this
 */
proto.authzed.api.v0.RelationTuple.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authzed.api.v0.RelationTuple.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.ObjectAndRelation.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.ObjectAndRelation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.ObjectAndRelation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.ObjectAndRelation.toObject = function(includeInstance, msg) {
  var f, obj = {
    namespace: jspb.Message.getFieldWithDefault(msg, 1, ""),
    objectId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    relation: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.ObjectAndRelation}
 */
proto.authzed.api.v0.ObjectAndRelation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.ObjectAndRelation;
  return proto.authzed.api.v0.ObjectAndRelation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.ObjectAndRelation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.ObjectAndRelation}
 */
proto.authzed.api.v0.ObjectAndRelation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNamespace(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRelation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.ObjectAndRelation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.ObjectAndRelation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.ObjectAndRelation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.ObjectAndRelation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNamespace();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getObjectId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRelation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string namespace = 1;
 * @return {string}
 */
proto.authzed.api.v0.ObjectAndRelation.prototype.getNamespace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.authzed.api.v0.ObjectAndRelation} returns this
 */
proto.authzed.api.v0.ObjectAndRelation.prototype.setNamespace = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string object_id = 2;
 * @return {string}
 */
proto.authzed.api.v0.ObjectAndRelation.prototype.getObjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.authzed.api.v0.ObjectAndRelation} returns this
 */
proto.authzed.api.v0.ObjectAndRelation.prototype.setObjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string relation = 3;
 * @return {string}
 */
proto.authzed.api.v0.ObjectAndRelation.prototype.getRelation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.authzed.api.v0.ObjectAndRelation} returns this
 */
proto.authzed.api.v0.ObjectAndRelation.prototype.setRelation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.RelationReference.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.RelationReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.RelationReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.RelationReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    namespace: jspb.Message.getFieldWithDefault(msg, 1, ""),
    relation: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.RelationReference}
 */
proto.authzed.api.v0.RelationReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.RelationReference;
  return proto.authzed.api.v0.RelationReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.RelationReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.RelationReference}
 */
proto.authzed.api.v0.RelationReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNamespace(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRelation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.RelationReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.RelationReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.RelationReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.RelationReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNamespace();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRelation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string namespace = 1;
 * @return {string}
 */
proto.authzed.api.v0.RelationReference.prototype.getNamespace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.authzed.api.v0.RelationReference} returns this
 */
proto.authzed.api.v0.RelationReference.prototype.setNamespace = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string relation = 3;
 * @return {string}
 */
proto.authzed.api.v0.RelationReference.prototype.getRelation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.authzed.api.v0.RelationReference} returns this
 */
proto.authzed.api.v0.RelationReference.prototype.setRelation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.authzed.api.v0.User.oneofGroups_ = [[2]];

/**
 * @enum {number}
 */
proto.authzed.api.v0.User.UserOneofCase = {
  USER_ONEOF_NOT_SET: 0,
  USERSET: 2
};

/**
 * @return {proto.authzed.api.v0.User.UserOneofCase}
 */
proto.authzed.api.v0.User.prototype.getUserOneofCase = function() {
  return /** @type {proto.authzed.api.v0.User.UserOneofCase} */(jspb.Message.computeOneofCase(this, proto.authzed.api.v0.User.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.User.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    userset: (f = msg.getUserset()) && proto.authzed.api.v0.ObjectAndRelation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.User}
 */
proto.authzed.api.v0.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.User;
  return proto.authzed.api.v0.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.User}
 */
proto.authzed.api.v0.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.authzed.api.v0.ObjectAndRelation;
      reader.readMessage(value,proto.authzed.api.v0.ObjectAndRelation.deserializeBinaryFromReader);
      msg.setUserset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserset();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.authzed.api.v0.ObjectAndRelation.serializeBinaryToWriter
    );
  }
};


/**
 * optional ObjectAndRelation userset = 2;
 * @return {?proto.authzed.api.v0.ObjectAndRelation}
 */
proto.authzed.api.v0.User.prototype.getUserset = function() {
  return /** @type{?proto.authzed.api.v0.ObjectAndRelation} */ (
    jspb.Message.getWrapperField(this, proto.authzed.api.v0.ObjectAndRelation, 2));
};


/**
 * @param {?proto.authzed.api.v0.ObjectAndRelation|undefined} value
 * @return {!proto.authzed.api.v0.User} returns this
*/
proto.authzed.api.v0.User.prototype.setUserset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.authzed.api.v0.User.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authzed.api.v0.User} returns this
 */
proto.authzed.api.v0.User.prototype.clearUserset = function() {
  return this.setUserset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authzed.api.v0.User.prototype.hasUserset = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.Zookie.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.Zookie.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.Zookie} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.Zookie.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.Zookie}
 */
proto.authzed.api.v0.Zookie.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.Zookie;
  return proto.authzed.api.v0.Zookie.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.Zookie} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.Zookie}
 */
proto.authzed.api.v0.Zookie.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.Zookie.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.Zookie.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.Zookie} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.Zookie.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.authzed.api.v0.Zookie.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.authzed.api.v0.Zookie} returns this
 */
proto.authzed.api.v0.Zookie.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.RelationTupleUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.RelationTupleUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.RelationTupleUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.RelationTupleUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    operation: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tuple: (f = msg.getTuple()) && proto.authzed.api.v0.RelationTuple.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.RelationTupleUpdate}
 */
proto.authzed.api.v0.RelationTupleUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.RelationTupleUpdate;
  return proto.authzed.api.v0.RelationTupleUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.RelationTupleUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.RelationTupleUpdate}
 */
proto.authzed.api.v0.RelationTupleUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.authzed.api.v0.RelationTupleUpdate.Operation} */ (reader.readEnum());
      msg.setOperation(value);
      break;
    case 2:
      var value = new proto.authzed.api.v0.RelationTuple;
      reader.readMessage(value,proto.authzed.api.v0.RelationTuple.deserializeBinaryFromReader);
      msg.setTuple(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.RelationTupleUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.RelationTupleUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.RelationTupleUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.RelationTupleUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperation();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTuple();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.authzed.api.v0.RelationTuple.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.authzed.api.v0.RelationTupleUpdate.Operation = {
  UNKNOWN: 0,
  CREATE: 1,
  TOUCH: 2,
  DELETE: 3
};

/**
 * optional Operation operation = 1;
 * @return {!proto.authzed.api.v0.RelationTupleUpdate.Operation}
 */
proto.authzed.api.v0.RelationTupleUpdate.prototype.getOperation = function() {
  return /** @type {!proto.authzed.api.v0.RelationTupleUpdate.Operation} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.authzed.api.v0.RelationTupleUpdate.Operation} value
 * @return {!proto.authzed.api.v0.RelationTupleUpdate} returns this
 */
proto.authzed.api.v0.RelationTupleUpdate.prototype.setOperation = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional RelationTuple tuple = 2;
 * @return {?proto.authzed.api.v0.RelationTuple}
 */
proto.authzed.api.v0.RelationTupleUpdate.prototype.getTuple = function() {
  return /** @type{?proto.authzed.api.v0.RelationTuple} */ (
    jspb.Message.getWrapperField(this, proto.authzed.api.v0.RelationTuple, 2));
};


/**
 * @param {?proto.authzed.api.v0.RelationTuple|undefined} value
 * @return {!proto.authzed.api.v0.RelationTupleUpdate} returns this
*/
proto.authzed.api.v0.RelationTupleUpdate.prototype.setTuple = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authzed.api.v0.RelationTupleUpdate} returns this
 */
proto.authzed.api.v0.RelationTupleUpdate.prototype.clearTuple = function() {
  return this.setTuple(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authzed.api.v0.RelationTupleUpdate.prototype.hasTuple = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.authzed.api.v0.RelationTupleTreeNode.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.authzed.api.v0.RelationTupleTreeNode.NodeTypeCase = {
  NODE_TYPE_NOT_SET: 0,
  INTERMEDIATE_NODE: 1,
  LEAF_NODE: 2
};

/**
 * @return {proto.authzed.api.v0.RelationTupleTreeNode.NodeTypeCase}
 */
proto.authzed.api.v0.RelationTupleTreeNode.prototype.getNodeTypeCase = function() {
  return /** @type {proto.authzed.api.v0.RelationTupleTreeNode.NodeTypeCase} */(jspb.Message.computeOneofCase(this, proto.authzed.api.v0.RelationTupleTreeNode.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.RelationTupleTreeNode.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.RelationTupleTreeNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.RelationTupleTreeNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.RelationTupleTreeNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    intermediateNode: (f = msg.getIntermediateNode()) && proto.authzed.api.v0.SetOperationUserset.toObject(includeInstance, f),
    leafNode: (f = msg.getLeafNode()) && proto.authzed.api.v0.DirectUserset.toObject(includeInstance, f),
    expanded: (f = msg.getExpanded()) && proto.authzed.api.v0.ObjectAndRelation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.RelationTupleTreeNode}
 */
proto.authzed.api.v0.RelationTupleTreeNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.RelationTupleTreeNode;
  return proto.authzed.api.v0.RelationTupleTreeNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.RelationTupleTreeNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.RelationTupleTreeNode}
 */
proto.authzed.api.v0.RelationTupleTreeNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.authzed.api.v0.SetOperationUserset;
      reader.readMessage(value,proto.authzed.api.v0.SetOperationUserset.deserializeBinaryFromReader);
      msg.setIntermediateNode(value);
      break;
    case 2:
      var value = new proto.authzed.api.v0.DirectUserset;
      reader.readMessage(value,proto.authzed.api.v0.DirectUserset.deserializeBinaryFromReader);
      msg.setLeafNode(value);
      break;
    case 3:
      var value = new proto.authzed.api.v0.ObjectAndRelation;
      reader.readMessage(value,proto.authzed.api.v0.ObjectAndRelation.deserializeBinaryFromReader);
      msg.setExpanded(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.RelationTupleTreeNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.RelationTupleTreeNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.RelationTupleTreeNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.RelationTupleTreeNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIntermediateNode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.authzed.api.v0.SetOperationUserset.serializeBinaryToWriter
    );
  }
  f = message.getLeafNode();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.authzed.api.v0.DirectUserset.serializeBinaryToWriter
    );
  }
  f = message.getExpanded();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.authzed.api.v0.ObjectAndRelation.serializeBinaryToWriter
    );
  }
};


/**
 * optional SetOperationUserset intermediate_node = 1;
 * @return {?proto.authzed.api.v0.SetOperationUserset}
 */
proto.authzed.api.v0.RelationTupleTreeNode.prototype.getIntermediateNode = function() {
  return /** @type{?proto.authzed.api.v0.SetOperationUserset} */ (
    jspb.Message.getWrapperField(this, proto.authzed.api.v0.SetOperationUserset, 1));
};


/**
 * @param {?proto.authzed.api.v0.SetOperationUserset|undefined} value
 * @return {!proto.authzed.api.v0.RelationTupleTreeNode} returns this
*/
proto.authzed.api.v0.RelationTupleTreeNode.prototype.setIntermediateNode = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.authzed.api.v0.RelationTupleTreeNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authzed.api.v0.RelationTupleTreeNode} returns this
 */
proto.authzed.api.v0.RelationTupleTreeNode.prototype.clearIntermediateNode = function() {
  return this.setIntermediateNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authzed.api.v0.RelationTupleTreeNode.prototype.hasIntermediateNode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DirectUserset leaf_node = 2;
 * @return {?proto.authzed.api.v0.DirectUserset}
 */
proto.authzed.api.v0.RelationTupleTreeNode.prototype.getLeafNode = function() {
  return /** @type{?proto.authzed.api.v0.DirectUserset} */ (
    jspb.Message.getWrapperField(this, proto.authzed.api.v0.DirectUserset, 2));
};


/**
 * @param {?proto.authzed.api.v0.DirectUserset|undefined} value
 * @return {!proto.authzed.api.v0.RelationTupleTreeNode} returns this
*/
proto.authzed.api.v0.RelationTupleTreeNode.prototype.setLeafNode = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.authzed.api.v0.RelationTupleTreeNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authzed.api.v0.RelationTupleTreeNode} returns this
 */
proto.authzed.api.v0.RelationTupleTreeNode.prototype.clearLeafNode = function() {
  return this.setLeafNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authzed.api.v0.RelationTupleTreeNode.prototype.hasLeafNode = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ObjectAndRelation expanded = 3;
 * @return {?proto.authzed.api.v0.ObjectAndRelation}
 */
proto.authzed.api.v0.RelationTupleTreeNode.prototype.getExpanded = function() {
  return /** @type{?proto.authzed.api.v0.ObjectAndRelation} */ (
    jspb.Message.getWrapperField(this, proto.authzed.api.v0.ObjectAndRelation, 3));
};


/**
 * @param {?proto.authzed.api.v0.ObjectAndRelation|undefined} value
 * @return {!proto.authzed.api.v0.RelationTupleTreeNode} returns this
*/
proto.authzed.api.v0.RelationTupleTreeNode.prototype.setExpanded = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authzed.api.v0.RelationTupleTreeNode} returns this
 */
proto.authzed.api.v0.RelationTupleTreeNode.prototype.clearExpanded = function() {
  return this.setExpanded(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authzed.api.v0.RelationTupleTreeNode.prototype.hasExpanded = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.authzed.api.v0.SetOperationUserset.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.SetOperationUserset.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.SetOperationUserset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.SetOperationUserset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.SetOperationUserset.toObject = function(includeInstance, msg) {
  var f, obj = {
    operation: jspb.Message.getFieldWithDefault(msg, 1, 0),
    childNodesList: jspb.Message.toObjectList(msg.getChildNodesList(),
    proto.authzed.api.v0.RelationTupleTreeNode.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.SetOperationUserset}
 */
proto.authzed.api.v0.SetOperationUserset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.SetOperationUserset;
  return proto.authzed.api.v0.SetOperationUserset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.SetOperationUserset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.SetOperationUserset}
 */
proto.authzed.api.v0.SetOperationUserset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.authzed.api.v0.SetOperationUserset.Operation} */ (reader.readEnum());
      msg.setOperation(value);
      break;
    case 2:
      var value = new proto.authzed.api.v0.RelationTupleTreeNode;
      reader.readMessage(value,proto.authzed.api.v0.RelationTupleTreeNode.deserializeBinaryFromReader);
      msg.addChildNodes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.SetOperationUserset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.SetOperationUserset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.SetOperationUserset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.SetOperationUserset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperation();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getChildNodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.authzed.api.v0.RelationTupleTreeNode.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.authzed.api.v0.SetOperationUserset.Operation = {
  INVALID: 0,
  UNION: 1,
  INTERSECTION: 2,
  EXCLUSION: 3
};

/**
 * optional Operation operation = 1;
 * @return {!proto.authzed.api.v0.SetOperationUserset.Operation}
 */
proto.authzed.api.v0.SetOperationUserset.prototype.getOperation = function() {
  return /** @type {!proto.authzed.api.v0.SetOperationUserset.Operation} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.authzed.api.v0.SetOperationUserset.Operation} value
 * @return {!proto.authzed.api.v0.SetOperationUserset} returns this
 */
proto.authzed.api.v0.SetOperationUserset.prototype.setOperation = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated RelationTupleTreeNode child_nodes = 2;
 * @return {!Array<!proto.authzed.api.v0.RelationTupleTreeNode>}
 */
proto.authzed.api.v0.SetOperationUserset.prototype.getChildNodesList = function() {
  return /** @type{!Array<!proto.authzed.api.v0.RelationTupleTreeNode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.authzed.api.v0.RelationTupleTreeNode, 2));
};


/**
 * @param {!Array<!proto.authzed.api.v0.RelationTupleTreeNode>} value
 * @return {!proto.authzed.api.v0.SetOperationUserset} returns this
*/
proto.authzed.api.v0.SetOperationUserset.prototype.setChildNodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.authzed.api.v0.RelationTupleTreeNode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.authzed.api.v0.RelationTupleTreeNode}
 */
proto.authzed.api.v0.SetOperationUserset.prototype.addChildNodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.authzed.api.v0.RelationTupleTreeNode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.authzed.api.v0.SetOperationUserset} returns this
 */
proto.authzed.api.v0.SetOperationUserset.prototype.clearChildNodesList = function() {
  return this.setChildNodesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.authzed.api.v0.DirectUserset.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.DirectUserset.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.DirectUserset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.DirectUserset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.DirectUserset.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.authzed.api.v0.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.DirectUserset}
 */
proto.authzed.api.v0.DirectUserset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.DirectUserset;
  return proto.authzed.api.v0.DirectUserset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.DirectUserset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.DirectUserset}
 */
proto.authzed.api.v0.DirectUserset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.authzed.api.v0.User;
      reader.readMessage(value,proto.authzed.api.v0.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.DirectUserset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.DirectUserset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.DirectUserset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.DirectUserset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.authzed.api.v0.User.serializeBinaryToWriter
    );
  }
};


/**
 * repeated User users = 1;
 * @return {!Array<!proto.authzed.api.v0.User>}
 */
proto.authzed.api.v0.DirectUserset.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.authzed.api.v0.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.authzed.api.v0.User, 1));
};


/**
 * @param {!Array<!proto.authzed.api.v0.User>} value
 * @return {!proto.authzed.api.v0.DirectUserset} returns this
*/
proto.authzed.api.v0.DirectUserset.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.authzed.api.v0.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.authzed.api.v0.User}
 */
proto.authzed.api.v0.DirectUserset.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.authzed.api.v0.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.authzed.api.v0.DirectUserset} returns this
 */
proto.authzed.api.v0.DirectUserset.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


goog.object.extend(exports, proto.authzed.api.v0);
