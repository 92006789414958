// source: authzed/api/v0/namespace.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_any_pb = require('google-protobuf/google/protobuf/any_pb.js');
goog.object.extend(proto, google_protobuf_any_pb);
var validate_validate_pb = require('../../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
var authzed_api_v0_core_pb = require('../../../authzed/api/v0/core_pb.js');
goog.object.extend(proto, authzed_api_v0_core_pb);
goog.exportSymbol('proto.authzed.api.v0.AllowedRelation', null, global);
goog.exportSymbol('proto.authzed.api.v0.AllowedRelation.PublicWildcard', null, global);
goog.exportSymbol('proto.authzed.api.v0.AllowedRelation.RelationOrWildcardCase', null, global);
goog.exportSymbol('proto.authzed.api.v0.ComputedUserset', null, global);
goog.exportSymbol('proto.authzed.api.v0.ComputedUserset.Object', null, global);
goog.exportSymbol('proto.authzed.api.v0.Metadata', null, global);
goog.exportSymbol('proto.authzed.api.v0.NamespaceDefinition', null, global);
goog.exportSymbol('proto.authzed.api.v0.Relation', null, global);
goog.exportSymbol('proto.authzed.api.v0.SetOperation', null, global);
goog.exportSymbol('proto.authzed.api.v0.SetOperation.Child', null, global);
goog.exportSymbol('proto.authzed.api.v0.SetOperation.Child.ChildTypeCase', null, global);
goog.exportSymbol('proto.authzed.api.v0.SetOperation.Child.This', null, global);
goog.exportSymbol('proto.authzed.api.v0.TupleToUserset', null, global);
goog.exportSymbol('proto.authzed.api.v0.TupleToUserset.Tupleset', null, global);
goog.exportSymbol('proto.authzed.api.v0.TypeInformation', null, global);
goog.exportSymbol('proto.authzed.api.v0.UsersetRewrite', null, global);
goog.exportSymbol('proto.authzed.api.v0.UsersetRewrite.RewriteOperationCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.Metadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.authzed.api.v0.Metadata.repeatedFields_, null);
};
goog.inherits(proto.authzed.api.v0.Metadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.Metadata.displayName = 'proto.authzed.api.v0.Metadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.NamespaceDefinition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.authzed.api.v0.NamespaceDefinition.repeatedFields_, null);
};
goog.inherits(proto.authzed.api.v0.NamespaceDefinition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.NamespaceDefinition.displayName = 'proto.authzed.api.v0.NamespaceDefinition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.Relation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authzed.api.v0.Relation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.Relation.displayName = 'proto.authzed.api.v0.Relation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.TypeInformation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.authzed.api.v0.TypeInformation.repeatedFields_, null);
};
goog.inherits(proto.authzed.api.v0.TypeInformation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.TypeInformation.displayName = 'proto.authzed.api.v0.TypeInformation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.AllowedRelation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.authzed.api.v0.AllowedRelation.oneofGroups_);
};
goog.inherits(proto.authzed.api.v0.AllowedRelation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.AllowedRelation.displayName = 'proto.authzed.api.v0.AllowedRelation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.AllowedRelation.PublicWildcard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authzed.api.v0.AllowedRelation.PublicWildcard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.AllowedRelation.PublicWildcard.displayName = 'proto.authzed.api.v0.AllowedRelation.PublicWildcard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.UsersetRewrite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.authzed.api.v0.UsersetRewrite.oneofGroups_);
};
goog.inherits(proto.authzed.api.v0.UsersetRewrite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.UsersetRewrite.displayName = 'proto.authzed.api.v0.UsersetRewrite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.SetOperation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.authzed.api.v0.SetOperation.repeatedFields_, null);
};
goog.inherits(proto.authzed.api.v0.SetOperation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.SetOperation.displayName = 'proto.authzed.api.v0.SetOperation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.SetOperation.Child = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.authzed.api.v0.SetOperation.Child.oneofGroups_);
};
goog.inherits(proto.authzed.api.v0.SetOperation.Child, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.SetOperation.Child.displayName = 'proto.authzed.api.v0.SetOperation.Child';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.SetOperation.Child.This = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authzed.api.v0.SetOperation.Child.This, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.SetOperation.Child.This.displayName = 'proto.authzed.api.v0.SetOperation.Child.This';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.TupleToUserset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authzed.api.v0.TupleToUserset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.TupleToUserset.displayName = 'proto.authzed.api.v0.TupleToUserset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.TupleToUserset.Tupleset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authzed.api.v0.TupleToUserset.Tupleset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.TupleToUserset.Tupleset.displayName = 'proto.authzed.api.v0.TupleToUserset.Tupleset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.ComputedUserset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authzed.api.v0.ComputedUserset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.ComputedUserset.displayName = 'proto.authzed.api.v0.ComputedUserset';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.authzed.api.v0.Metadata.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.Metadata.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.Metadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.Metadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.Metadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    metadataMessageList: jspb.Message.toObjectList(msg.getMetadataMessageList(),
    google_protobuf_any_pb.Any.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.Metadata}
 */
proto.authzed.api.v0.Metadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.Metadata;
  return proto.authzed.api.v0.Metadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.Metadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.Metadata}
 */
proto.authzed.api.v0.Metadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_any_pb.Any;
      reader.readMessage(value,google_protobuf_any_pb.Any.deserializeBinaryFromReader);
      msg.addMetadataMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.Metadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.Metadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.Metadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.Metadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetadataMessageList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      google_protobuf_any_pb.Any.serializeBinaryToWriter
    );
  }
};


/**
 * repeated google.protobuf.Any metadata_message = 1;
 * @return {!Array<!proto.google.protobuf.Any>}
 */
proto.authzed.api.v0.Metadata.prototype.getMetadataMessageList = function() {
  return /** @type{!Array<!proto.google.protobuf.Any>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_any_pb.Any, 1));
};


/**
 * @param {!Array<!proto.google.protobuf.Any>} value
 * @return {!proto.authzed.api.v0.Metadata} returns this
*/
proto.authzed.api.v0.Metadata.prototype.setMetadataMessageList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.google.protobuf.Any=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.Any}
 */
proto.authzed.api.v0.Metadata.prototype.addMetadataMessage = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.google.protobuf.Any, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.authzed.api.v0.Metadata} returns this
 */
proto.authzed.api.v0.Metadata.prototype.clearMetadataMessageList = function() {
  return this.setMetadataMessageList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.authzed.api.v0.NamespaceDefinition.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.NamespaceDefinition.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.NamespaceDefinition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.NamespaceDefinition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.NamespaceDefinition.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    relationList: jspb.Message.toObjectList(msg.getRelationList(),
    proto.authzed.api.v0.Relation.toObject, includeInstance),
    metadata: (f = msg.getMetadata()) && proto.authzed.api.v0.Metadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.NamespaceDefinition}
 */
proto.authzed.api.v0.NamespaceDefinition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.NamespaceDefinition;
  return proto.authzed.api.v0.NamespaceDefinition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.NamespaceDefinition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.NamespaceDefinition}
 */
proto.authzed.api.v0.NamespaceDefinition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.authzed.api.v0.Relation;
      reader.readMessage(value,proto.authzed.api.v0.Relation.deserializeBinaryFromReader);
      msg.addRelation(value);
      break;
    case 3:
      var value = new proto.authzed.api.v0.Metadata;
      reader.readMessage(value,proto.authzed.api.v0.Metadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.NamespaceDefinition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.NamespaceDefinition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.NamespaceDefinition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.NamespaceDefinition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRelationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.authzed.api.v0.Relation.serializeBinaryToWriter
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.authzed.api.v0.Metadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.authzed.api.v0.NamespaceDefinition.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.authzed.api.v0.NamespaceDefinition} returns this
 */
proto.authzed.api.v0.NamespaceDefinition.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Relation relation = 2;
 * @return {!Array<!proto.authzed.api.v0.Relation>}
 */
proto.authzed.api.v0.NamespaceDefinition.prototype.getRelationList = function() {
  return /** @type{!Array<!proto.authzed.api.v0.Relation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.authzed.api.v0.Relation, 2));
};


/**
 * @param {!Array<!proto.authzed.api.v0.Relation>} value
 * @return {!proto.authzed.api.v0.NamespaceDefinition} returns this
*/
proto.authzed.api.v0.NamespaceDefinition.prototype.setRelationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.authzed.api.v0.Relation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.authzed.api.v0.Relation}
 */
proto.authzed.api.v0.NamespaceDefinition.prototype.addRelation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.authzed.api.v0.Relation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.authzed.api.v0.NamespaceDefinition} returns this
 */
proto.authzed.api.v0.NamespaceDefinition.prototype.clearRelationList = function() {
  return this.setRelationList([]);
};


/**
 * optional Metadata metadata = 3;
 * @return {?proto.authzed.api.v0.Metadata}
 */
proto.authzed.api.v0.NamespaceDefinition.prototype.getMetadata = function() {
  return /** @type{?proto.authzed.api.v0.Metadata} */ (
    jspb.Message.getWrapperField(this, proto.authzed.api.v0.Metadata, 3));
};


/**
 * @param {?proto.authzed.api.v0.Metadata|undefined} value
 * @return {!proto.authzed.api.v0.NamespaceDefinition} returns this
*/
proto.authzed.api.v0.NamespaceDefinition.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authzed.api.v0.NamespaceDefinition} returns this
 */
proto.authzed.api.v0.NamespaceDefinition.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authzed.api.v0.NamespaceDefinition.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.Relation.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.Relation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.Relation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.Relation.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    usersetRewrite: (f = msg.getUsersetRewrite()) && proto.authzed.api.v0.UsersetRewrite.toObject(includeInstance, f),
    typeInformation: (f = msg.getTypeInformation()) && proto.authzed.api.v0.TypeInformation.toObject(includeInstance, f),
    metadata: (f = msg.getMetadata()) && proto.authzed.api.v0.Metadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.Relation}
 */
proto.authzed.api.v0.Relation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.Relation;
  return proto.authzed.api.v0.Relation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.Relation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.Relation}
 */
proto.authzed.api.v0.Relation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.authzed.api.v0.UsersetRewrite;
      reader.readMessage(value,proto.authzed.api.v0.UsersetRewrite.deserializeBinaryFromReader);
      msg.setUsersetRewrite(value);
      break;
    case 3:
      var value = new proto.authzed.api.v0.TypeInformation;
      reader.readMessage(value,proto.authzed.api.v0.TypeInformation.deserializeBinaryFromReader);
      msg.setTypeInformation(value);
      break;
    case 4:
      var value = new proto.authzed.api.v0.Metadata;
      reader.readMessage(value,proto.authzed.api.v0.Metadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.Relation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.Relation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.Relation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.Relation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUsersetRewrite();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.authzed.api.v0.UsersetRewrite.serializeBinaryToWriter
    );
  }
  f = message.getTypeInformation();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.authzed.api.v0.TypeInformation.serializeBinaryToWriter
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.authzed.api.v0.Metadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.authzed.api.v0.Relation.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.authzed.api.v0.Relation} returns this
 */
proto.authzed.api.v0.Relation.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional UsersetRewrite userset_rewrite = 2;
 * @return {?proto.authzed.api.v0.UsersetRewrite}
 */
proto.authzed.api.v0.Relation.prototype.getUsersetRewrite = function() {
  return /** @type{?proto.authzed.api.v0.UsersetRewrite} */ (
    jspb.Message.getWrapperField(this, proto.authzed.api.v0.UsersetRewrite, 2));
};


/**
 * @param {?proto.authzed.api.v0.UsersetRewrite|undefined} value
 * @return {!proto.authzed.api.v0.Relation} returns this
*/
proto.authzed.api.v0.Relation.prototype.setUsersetRewrite = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authzed.api.v0.Relation} returns this
 */
proto.authzed.api.v0.Relation.prototype.clearUsersetRewrite = function() {
  return this.setUsersetRewrite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authzed.api.v0.Relation.prototype.hasUsersetRewrite = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TypeInformation type_information = 3;
 * @return {?proto.authzed.api.v0.TypeInformation}
 */
proto.authzed.api.v0.Relation.prototype.getTypeInformation = function() {
  return /** @type{?proto.authzed.api.v0.TypeInformation} */ (
    jspb.Message.getWrapperField(this, proto.authzed.api.v0.TypeInformation, 3));
};


/**
 * @param {?proto.authzed.api.v0.TypeInformation|undefined} value
 * @return {!proto.authzed.api.v0.Relation} returns this
*/
proto.authzed.api.v0.Relation.prototype.setTypeInformation = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authzed.api.v0.Relation} returns this
 */
proto.authzed.api.v0.Relation.prototype.clearTypeInformation = function() {
  return this.setTypeInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authzed.api.v0.Relation.prototype.hasTypeInformation = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Metadata metadata = 4;
 * @return {?proto.authzed.api.v0.Metadata}
 */
proto.authzed.api.v0.Relation.prototype.getMetadata = function() {
  return /** @type{?proto.authzed.api.v0.Metadata} */ (
    jspb.Message.getWrapperField(this, proto.authzed.api.v0.Metadata, 4));
};


/**
 * @param {?proto.authzed.api.v0.Metadata|undefined} value
 * @return {!proto.authzed.api.v0.Relation} returns this
*/
proto.authzed.api.v0.Relation.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authzed.api.v0.Relation} returns this
 */
proto.authzed.api.v0.Relation.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authzed.api.v0.Relation.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.authzed.api.v0.TypeInformation.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.TypeInformation.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.TypeInformation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.TypeInformation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.TypeInformation.toObject = function(includeInstance, msg) {
  var f, obj = {
    allowedDirectRelationsList: jspb.Message.toObjectList(msg.getAllowedDirectRelationsList(),
    proto.authzed.api.v0.AllowedRelation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.TypeInformation}
 */
proto.authzed.api.v0.TypeInformation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.TypeInformation;
  return proto.authzed.api.v0.TypeInformation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.TypeInformation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.TypeInformation}
 */
proto.authzed.api.v0.TypeInformation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.authzed.api.v0.AllowedRelation;
      reader.readMessage(value,proto.authzed.api.v0.AllowedRelation.deserializeBinaryFromReader);
      msg.addAllowedDirectRelations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.TypeInformation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.TypeInformation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.TypeInformation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.TypeInformation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllowedDirectRelationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.authzed.api.v0.AllowedRelation.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AllowedRelation allowed_direct_relations = 1;
 * @return {!Array<!proto.authzed.api.v0.AllowedRelation>}
 */
proto.authzed.api.v0.TypeInformation.prototype.getAllowedDirectRelationsList = function() {
  return /** @type{!Array<!proto.authzed.api.v0.AllowedRelation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.authzed.api.v0.AllowedRelation, 1));
};


/**
 * @param {!Array<!proto.authzed.api.v0.AllowedRelation>} value
 * @return {!proto.authzed.api.v0.TypeInformation} returns this
*/
proto.authzed.api.v0.TypeInformation.prototype.setAllowedDirectRelationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.authzed.api.v0.AllowedRelation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.authzed.api.v0.AllowedRelation}
 */
proto.authzed.api.v0.TypeInformation.prototype.addAllowedDirectRelations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.authzed.api.v0.AllowedRelation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.authzed.api.v0.TypeInformation} returns this
 */
proto.authzed.api.v0.TypeInformation.prototype.clearAllowedDirectRelationsList = function() {
  return this.setAllowedDirectRelationsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.authzed.api.v0.AllowedRelation.oneofGroups_ = [[3,4]];

/**
 * @enum {number}
 */
proto.authzed.api.v0.AllowedRelation.RelationOrWildcardCase = {
  RELATION_OR_WILDCARD_NOT_SET: 0,
  RELATION: 3,
  PUBLIC_WILDCARD: 4
};

/**
 * @return {proto.authzed.api.v0.AllowedRelation.RelationOrWildcardCase}
 */
proto.authzed.api.v0.AllowedRelation.prototype.getRelationOrWildcardCase = function() {
  return /** @type {proto.authzed.api.v0.AllowedRelation.RelationOrWildcardCase} */(jspb.Message.computeOneofCase(this, proto.authzed.api.v0.AllowedRelation.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.AllowedRelation.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.AllowedRelation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.AllowedRelation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.AllowedRelation.toObject = function(includeInstance, msg) {
  var f, obj = {
    namespace: jspb.Message.getFieldWithDefault(msg, 1, ""),
    relation: jspb.Message.getFieldWithDefault(msg, 3, ""),
    publicWildcard: (f = msg.getPublicWildcard()) && proto.authzed.api.v0.AllowedRelation.PublicWildcard.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.AllowedRelation}
 */
proto.authzed.api.v0.AllowedRelation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.AllowedRelation;
  return proto.authzed.api.v0.AllowedRelation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.AllowedRelation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.AllowedRelation}
 */
proto.authzed.api.v0.AllowedRelation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNamespace(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRelation(value);
      break;
    case 4:
      var value = new proto.authzed.api.v0.AllowedRelation.PublicWildcard;
      reader.readMessage(value,proto.authzed.api.v0.AllowedRelation.PublicWildcard.deserializeBinaryFromReader);
      msg.setPublicWildcard(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.AllowedRelation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.AllowedRelation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.AllowedRelation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.AllowedRelation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNamespace();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPublicWildcard();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.authzed.api.v0.AllowedRelation.PublicWildcard.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.AllowedRelation.PublicWildcard.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.AllowedRelation.PublicWildcard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.AllowedRelation.PublicWildcard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.AllowedRelation.PublicWildcard.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.AllowedRelation.PublicWildcard}
 */
proto.authzed.api.v0.AllowedRelation.PublicWildcard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.AllowedRelation.PublicWildcard;
  return proto.authzed.api.v0.AllowedRelation.PublicWildcard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.AllowedRelation.PublicWildcard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.AllowedRelation.PublicWildcard}
 */
proto.authzed.api.v0.AllowedRelation.PublicWildcard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.AllowedRelation.PublicWildcard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.AllowedRelation.PublicWildcard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.AllowedRelation.PublicWildcard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.AllowedRelation.PublicWildcard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * optional string namespace = 1;
 * @return {string}
 */
proto.authzed.api.v0.AllowedRelation.prototype.getNamespace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.authzed.api.v0.AllowedRelation} returns this
 */
proto.authzed.api.v0.AllowedRelation.prototype.setNamespace = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string relation = 3;
 * @return {string}
 */
proto.authzed.api.v0.AllowedRelation.prototype.getRelation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.authzed.api.v0.AllowedRelation} returns this
 */
proto.authzed.api.v0.AllowedRelation.prototype.setRelation = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.authzed.api.v0.AllowedRelation.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.authzed.api.v0.AllowedRelation} returns this
 */
proto.authzed.api.v0.AllowedRelation.prototype.clearRelation = function() {
  return jspb.Message.setOneofField(this, 3, proto.authzed.api.v0.AllowedRelation.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authzed.api.v0.AllowedRelation.prototype.hasRelation = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PublicWildcard public_wildcard = 4;
 * @return {?proto.authzed.api.v0.AllowedRelation.PublicWildcard}
 */
proto.authzed.api.v0.AllowedRelation.prototype.getPublicWildcard = function() {
  return /** @type{?proto.authzed.api.v0.AllowedRelation.PublicWildcard} */ (
    jspb.Message.getWrapperField(this, proto.authzed.api.v0.AllowedRelation.PublicWildcard, 4));
};


/**
 * @param {?proto.authzed.api.v0.AllowedRelation.PublicWildcard|undefined} value
 * @return {!proto.authzed.api.v0.AllowedRelation} returns this
*/
proto.authzed.api.v0.AllowedRelation.prototype.setPublicWildcard = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.authzed.api.v0.AllowedRelation.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authzed.api.v0.AllowedRelation} returns this
 */
proto.authzed.api.v0.AllowedRelation.prototype.clearPublicWildcard = function() {
  return this.setPublicWildcard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authzed.api.v0.AllowedRelation.prototype.hasPublicWildcard = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.authzed.api.v0.UsersetRewrite.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.authzed.api.v0.UsersetRewrite.RewriteOperationCase = {
  REWRITE_OPERATION_NOT_SET: 0,
  UNION: 1,
  INTERSECTION: 2,
  EXCLUSION: 3
};

/**
 * @return {proto.authzed.api.v0.UsersetRewrite.RewriteOperationCase}
 */
proto.authzed.api.v0.UsersetRewrite.prototype.getRewriteOperationCase = function() {
  return /** @type {proto.authzed.api.v0.UsersetRewrite.RewriteOperationCase} */(jspb.Message.computeOneofCase(this, proto.authzed.api.v0.UsersetRewrite.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.UsersetRewrite.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.UsersetRewrite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.UsersetRewrite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.UsersetRewrite.toObject = function(includeInstance, msg) {
  var f, obj = {
    union: (f = msg.getUnion()) && proto.authzed.api.v0.SetOperation.toObject(includeInstance, f),
    intersection: (f = msg.getIntersection()) && proto.authzed.api.v0.SetOperation.toObject(includeInstance, f),
    exclusion: (f = msg.getExclusion()) && proto.authzed.api.v0.SetOperation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.UsersetRewrite}
 */
proto.authzed.api.v0.UsersetRewrite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.UsersetRewrite;
  return proto.authzed.api.v0.UsersetRewrite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.UsersetRewrite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.UsersetRewrite}
 */
proto.authzed.api.v0.UsersetRewrite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.authzed.api.v0.SetOperation;
      reader.readMessage(value,proto.authzed.api.v0.SetOperation.deserializeBinaryFromReader);
      msg.setUnion(value);
      break;
    case 2:
      var value = new proto.authzed.api.v0.SetOperation;
      reader.readMessage(value,proto.authzed.api.v0.SetOperation.deserializeBinaryFromReader);
      msg.setIntersection(value);
      break;
    case 3:
      var value = new proto.authzed.api.v0.SetOperation;
      reader.readMessage(value,proto.authzed.api.v0.SetOperation.deserializeBinaryFromReader);
      msg.setExclusion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.UsersetRewrite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.UsersetRewrite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.UsersetRewrite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.UsersetRewrite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnion();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.authzed.api.v0.SetOperation.serializeBinaryToWriter
    );
  }
  f = message.getIntersection();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.authzed.api.v0.SetOperation.serializeBinaryToWriter
    );
  }
  f = message.getExclusion();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.authzed.api.v0.SetOperation.serializeBinaryToWriter
    );
  }
};


/**
 * optional SetOperation union = 1;
 * @return {?proto.authzed.api.v0.SetOperation}
 */
proto.authzed.api.v0.UsersetRewrite.prototype.getUnion = function() {
  return /** @type{?proto.authzed.api.v0.SetOperation} */ (
    jspb.Message.getWrapperField(this, proto.authzed.api.v0.SetOperation, 1));
};


/**
 * @param {?proto.authzed.api.v0.SetOperation|undefined} value
 * @return {!proto.authzed.api.v0.UsersetRewrite} returns this
*/
proto.authzed.api.v0.UsersetRewrite.prototype.setUnion = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.authzed.api.v0.UsersetRewrite.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authzed.api.v0.UsersetRewrite} returns this
 */
proto.authzed.api.v0.UsersetRewrite.prototype.clearUnion = function() {
  return this.setUnion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authzed.api.v0.UsersetRewrite.prototype.hasUnion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SetOperation intersection = 2;
 * @return {?proto.authzed.api.v0.SetOperation}
 */
proto.authzed.api.v0.UsersetRewrite.prototype.getIntersection = function() {
  return /** @type{?proto.authzed.api.v0.SetOperation} */ (
    jspb.Message.getWrapperField(this, proto.authzed.api.v0.SetOperation, 2));
};


/**
 * @param {?proto.authzed.api.v0.SetOperation|undefined} value
 * @return {!proto.authzed.api.v0.UsersetRewrite} returns this
*/
proto.authzed.api.v0.UsersetRewrite.prototype.setIntersection = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.authzed.api.v0.UsersetRewrite.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authzed.api.v0.UsersetRewrite} returns this
 */
proto.authzed.api.v0.UsersetRewrite.prototype.clearIntersection = function() {
  return this.setIntersection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authzed.api.v0.UsersetRewrite.prototype.hasIntersection = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SetOperation exclusion = 3;
 * @return {?proto.authzed.api.v0.SetOperation}
 */
proto.authzed.api.v0.UsersetRewrite.prototype.getExclusion = function() {
  return /** @type{?proto.authzed.api.v0.SetOperation} */ (
    jspb.Message.getWrapperField(this, proto.authzed.api.v0.SetOperation, 3));
};


/**
 * @param {?proto.authzed.api.v0.SetOperation|undefined} value
 * @return {!proto.authzed.api.v0.UsersetRewrite} returns this
*/
proto.authzed.api.v0.UsersetRewrite.prototype.setExclusion = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.authzed.api.v0.UsersetRewrite.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authzed.api.v0.UsersetRewrite} returns this
 */
proto.authzed.api.v0.UsersetRewrite.prototype.clearExclusion = function() {
  return this.setExclusion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authzed.api.v0.UsersetRewrite.prototype.hasExclusion = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.authzed.api.v0.SetOperation.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.SetOperation.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.SetOperation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.SetOperation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.SetOperation.toObject = function(includeInstance, msg) {
  var f, obj = {
    childList: jspb.Message.toObjectList(msg.getChildList(),
    proto.authzed.api.v0.SetOperation.Child.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.SetOperation}
 */
proto.authzed.api.v0.SetOperation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.SetOperation;
  return proto.authzed.api.v0.SetOperation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.SetOperation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.SetOperation}
 */
proto.authzed.api.v0.SetOperation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.authzed.api.v0.SetOperation.Child;
      reader.readMessage(value,proto.authzed.api.v0.SetOperation.Child.deserializeBinaryFromReader);
      msg.addChild(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.SetOperation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.SetOperation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.SetOperation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.SetOperation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChildList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.authzed.api.v0.SetOperation.Child.serializeBinaryToWriter
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.authzed.api.v0.SetOperation.Child.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.authzed.api.v0.SetOperation.Child.ChildTypeCase = {
  CHILD_TYPE_NOT_SET: 0,
  _THIS: 1,
  COMPUTED_USERSET: 2,
  TUPLE_TO_USERSET: 3,
  USERSET_REWRITE: 4
};

/**
 * @return {proto.authzed.api.v0.SetOperation.Child.ChildTypeCase}
 */
proto.authzed.api.v0.SetOperation.Child.prototype.getChildTypeCase = function() {
  return /** @type {proto.authzed.api.v0.SetOperation.Child.ChildTypeCase} */(jspb.Message.computeOneofCase(this, proto.authzed.api.v0.SetOperation.Child.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.SetOperation.Child.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.SetOperation.Child.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.SetOperation.Child} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.SetOperation.Child.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_this: (f = msg.getThis()) && proto.authzed.api.v0.SetOperation.Child.This.toObject(includeInstance, f),
    computedUserset: (f = msg.getComputedUserset()) && proto.authzed.api.v0.ComputedUserset.toObject(includeInstance, f),
    tupleToUserset: (f = msg.getTupleToUserset()) && proto.authzed.api.v0.TupleToUserset.toObject(includeInstance, f),
    usersetRewrite: (f = msg.getUsersetRewrite()) && proto.authzed.api.v0.UsersetRewrite.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.SetOperation.Child}
 */
proto.authzed.api.v0.SetOperation.Child.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.SetOperation.Child;
  return proto.authzed.api.v0.SetOperation.Child.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.SetOperation.Child} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.SetOperation.Child}
 */
proto.authzed.api.v0.SetOperation.Child.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.authzed.api.v0.SetOperation.Child.This;
      reader.readMessage(value,proto.authzed.api.v0.SetOperation.Child.This.deserializeBinaryFromReader);
      msg.setThis(value);
      break;
    case 2:
      var value = new proto.authzed.api.v0.ComputedUserset;
      reader.readMessage(value,proto.authzed.api.v0.ComputedUserset.deserializeBinaryFromReader);
      msg.setComputedUserset(value);
      break;
    case 3:
      var value = new proto.authzed.api.v0.TupleToUserset;
      reader.readMessage(value,proto.authzed.api.v0.TupleToUserset.deserializeBinaryFromReader);
      msg.setTupleToUserset(value);
      break;
    case 4:
      var value = new proto.authzed.api.v0.UsersetRewrite;
      reader.readMessage(value,proto.authzed.api.v0.UsersetRewrite.deserializeBinaryFromReader);
      msg.setUsersetRewrite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.SetOperation.Child.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.SetOperation.Child.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.SetOperation.Child} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.SetOperation.Child.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getThis();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.authzed.api.v0.SetOperation.Child.This.serializeBinaryToWriter
    );
  }
  f = message.getComputedUserset();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.authzed.api.v0.ComputedUserset.serializeBinaryToWriter
    );
  }
  f = message.getTupleToUserset();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.authzed.api.v0.TupleToUserset.serializeBinaryToWriter
    );
  }
  f = message.getUsersetRewrite();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.authzed.api.v0.UsersetRewrite.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.SetOperation.Child.This.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.SetOperation.Child.This.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.SetOperation.Child.This} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.SetOperation.Child.This.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.SetOperation.Child.This}
 */
proto.authzed.api.v0.SetOperation.Child.This.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.SetOperation.Child.This;
  return proto.authzed.api.v0.SetOperation.Child.This.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.SetOperation.Child.This} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.SetOperation.Child.This}
 */
proto.authzed.api.v0.SetOperation.Child.This.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.SetOperation.Child.This.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.SetOperation.Child.This.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.SetOperation.Child.This} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.SetOperation.Child.This.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * optional This _this = 1;
 * @return {?proto.authzed.api.v0.SetOperation.Child.This}
 */
proto.authzed.api.v0.SetOperation.Child.prototype.getThis = function() {
  return /** @type{?proto.authzed.api.v0.SetOperation.Child.This} */ (
    jspb.Message.getWrapperField(this, proto.authzed.api.v0.SetOperation.Child.This, 1));
};


/**
 * @param {?proto.authzed.api.v0.SetOperation.Child.This|undefined} value
 * @return {!proto.authzed.api.v0.SetOperation.Child} returns this
*/
proto.authzed.api.v0.SetOperation.Child.prototype.setThis = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.authzed.api.v0.SetOperation.Child.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authzed.api.v0.SetOperation.Child} returns this
 */
proto.authzed.api.v0.SetOperation.Child.prototype.clearThis = function() {
  return this.setThis(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authzed.api.v0.SetOperation.Child.prototype.hasThis = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ComputedUserset computed_userset = 2;
 * @return {?proto.authzed.api.v0.ComputedUserset}
 */
proto.authzed.api.v0.SetOperation.Child.prototype.getComputedUserset = function() {
  return /** @type{?proto.authzed.api.v0.ComputedUserset} */ (
    jspb.Message.getWrapperField(this, proto.authzed.api.v0.ComputedUserset, 2));
};


/**
 * @param {?proto.authzed.api.v0.ComputedUserset|undefined} value
 * @return {!proto.authzed.api.v0.SetOperation.Child} returns this
*/
proto.authzed.api.v0.SetOperation.Child.prototype.setComputedUserset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.authzed.api.v0.SetOperation.Child.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authzed.api.v0.SetOperation.Child} returns this
 */
proto.authzed.api.v0.SetOperation.Child.prototype.clearComputedUserset = function() {
  return this.setComputedUserset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authzed.api.v0.SetOperation.Child.prototype.hasComputedUserset = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TupleToUserset tuple_to_userset = 3;
 * @return {?proto.authzed.api.v0.TupleToUserset}
 */
proto.authzed.api.v0.SetOperation.Child.prototype.getTupleToUserset = function() {
  return /** @type{?proto.authzed.api.v0.TupleToUserset} */ (
    jspb.Message.getWrapperField(this, proto.authzed.api.v0.TupleToUserset, 3));
};


/**
 * @param {?proto.authzed.api.v0.TupleToUserset|undefined} value
 * @return {!proto.authzed.api.v0.SetOperation.Child} returns this
*/
proto.authzed.api.v0.SetOperation.Child.prototype.setTupleToUserset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.authzed.api.v0.SetOperation.Child.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authzed.api.v0.SetOperation.Child} returns this
 */
proto.authzed.api.v0.SetOperation.Child.prototype.clearTupleToUserset = function() {
  return this.setTupleToUserset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authzed.api.v0.SetOperation.Child.prototype.hasTupleToUserset = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional UsersetRewrite userset_rewrite = 4;
 * @return {?proto.authzed.api.v0.UsersetRewrite}
 */
proto.authzed.api.v0.SetOperation.Child.prototype.getUsersetRewrite = function() {
  return /** @type{?proto.authzed.api.v0.UsersetRewrite} */ (
    jspb.Message.getWrapperField(this, proto.authzed.api.v0.UsersetRewrite, 4));
};


/**
 * @param {?proto.authzed.api.v0.UsersetRewrite|undefined} value
 * @return {!proto.authzed.api.v0.SetOperation.Child} returns this
*/
proto.authzed.api.v0.SetOperation.Child.prototype.setUsersetRewrite = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.authzed.api.v0.SetOperation.Child.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authzed.api.v0.SetOperation.Child} returns this
 */
proto.authzed.api.v0.SetOperation.Child.prototype.clearUsersetRewrite = function() {
  return this.setUsersetRewrite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authzed.api.v0.SetOperation.Child.prototype.hasUsersetRewrite = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated Child child = 1;
 * @return {!Array<!proto.authzed.api.v0.SetOperation.Child>}
 */
proto.authzed.api.v0.SetOperation.prototype.getChildList = function() {
  return /** @type{!Array<!proto.authzed.api.v0.SetOperation.Child>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.authzed.api.v0.SetOperation.Child, 1));
};


/**
 * @param {!Array<!proto.authzed.api.v0.SetOperation.Child>} value
 * @return {!proto.authzed.api.v0.SetOperation} returns this
*/
proto.authzed.api.v0.SetOperation.prototype.setChildList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.authzed.api.v0.SetOperation.Child=} opt_value
 * @param {number=} opt_index
 * @return {!proto.authzed.api.v0.SetOperation.Child}
 */
proto.authzed.api.v0.SetOperation.prototype.addChild = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.authzed.api.v0.SetOperation.Child, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.authzed.api.v0.SetOperation} returns this
 */
proto.authzed.api.v0.SetOperation.prototype.clearChildList = function() {
  return this.setChildList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.TupleToUserset.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.TupleToUserset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.TupleToUserset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.TupleToUserset.toObject = function(includeInstance, msg) {
  var f, obj = {
    tupleset: (f = msg.getTupleset()) && proto.authzed.api.v0.TupleToUserset.Tupleset.toObject(includeInstance, f),
    computedUserset: (f = msg.getComputedUserset()) && proto.authzed.api.v0.ComputedUserset.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.TupleToUserset}
 */
proto.authzed.api.v0.TupleToUserset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.TupleToUserset;
  return proto.authzed.api.v0.TupleToUserset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.TupleToUserset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.TupleToUserset}
 */
proto.authzed.api.v0.TupleToUserset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.authzed.api.v0.TupleToUserset.Tupleset;
      reader.readMessage(value,proto.authzed.api.v0.TupleToUserset.Tupleset.deserializeBinaryFromReader);
      msg.setTupleset(value);
      break;
    case 2:
      var value = new proto.authzed.api.v0.ComputedUserset;
      reader.readMessage(value,proto.authzed.api.v0.ComputedUserset.deserializeBinaryFromReader);
      msg.setComputedUserset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.TupleToUserset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.TupleToUserset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.TupleToUserset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.TupleToUserset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTupleset();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.authzed.api.v0.TupleToUserset.Tupleset.serializeBinaryToWriter
    );
  }
  f = message.getComputedUserset();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.authzed.api.v0.ComputedUserset.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.TupleToUserset.Tupleset.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.TupleToUserset.Tupleset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.TupleToUserset.Tupleset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.TupleToUserset.Tupleset.toObject = function(includeInstance, msg) {
  var f, obj = {
    relation: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.TupleToUserset.Tupleset}
 */
proto.authzed.api.v0.TupleToUserset.Tupleset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.TupleToUserset.Tupleset;
  return proto.authzed.api.v0.TupleToUserset.Tupleset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.TupleToUserset.Tupleset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.TupleToUserset.Tupleset}
 */
proto.authzed.api.v0.TupleToUserset.Tupleset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRelation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.TupleToUserset.Tupleset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.TupleToUserset.Tupleset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.TupleToUserset.Tupleset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.TupleToUserset.Tupleset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRelation();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string relation = 1;
 * @return {string}
 */
proto.authzed.api.v0.TupleToUserset.Tupleset.prototype.getRelation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.authzed.api.v0.TupleToUserset.Tupleset} returns this
 */
proto.authzed.api.v0.TupleToUserset.Tupleset.prototype.setRelation = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Tupleset tupleset = 1;
 * @return {?proto.authzed.api.v0.TupleToUserset.Tupleset}
 */
proto.authzed.api.v0.TupleToUserset.prototype.getTupleset = function() {
  return /** @type{?proto.authzed.api.v0.TupleToUserset.Tupleset} */ (
    jspb.Message.getWrapperField(this, proto.authzed.api.v0.TupleToUserset.Tupleset, 1));
};


/**
 * @param {?proto.authzed.api.v0.TupleToUserset.Tupleset|undefined} value
 * @return {!proto.authzed.api.v0.TupleToUserset} returns this
*/
proto.authzed.api.v0.TupleToUserset.prototype.setTupleset = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authzed.api.v0.TupleToUserset} returns this
 */
proto.authzed.api.v0.TupleToUserset.prototype.clearTupleset = function() {
  return this.setTupleset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authzed.api.v0.TupleToUserset.prototype.hasTupleset = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ComputedUserset computed_userset = 2;
 * @return {?proto.authzed.api.v0.ComputedUserset}
 */
proto.authzed.api.v0.TupleToUserset.prototype.getComputedUserset = function() {
  return /** @type{?proto.authzed.api.v0.ComputedUserset} */ (
    jspb.Message.getWrapperField(this, proto.authzed.api.v0.ComputedUserset, 2));
};


/**
 * @param {?proto.authzed.api.v0.ComputedUserset|undefined} value
 * @return {!proto.authzed.api.v0.TupleToUserset} returns this
*/
proto.authzed.api.v0.TupleToUserset.prototype.setComputedUserset = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authzed.api.v0.TupleToUserset} returns this
 */
proto.authzed.api.v0.TupleToUserset.prototype.clearComputedUserset = function() {
  return this.setComputedUserset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authzed.api.v0.TupleToUserset.prototype.hasComputedUserset = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.ComputedUserset.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.ComputedUserset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.ComputedUserset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.ComputedUserset.toObject = function(includeInstance, msg) {
  var f, obj = {
    object: jspb.Message.getFieldWithDefault(msg, 1, 0),
    relation: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.ComputedUserset}
 */
proto.authzed.api.v0.ComputedUserset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.ComputedUserset;
  return proto.authzed.api.v0.ComputedUserset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.ComputedUserset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.ComputedUserset}
 */
proto.authzed.api.v0.ComputedUserset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.authzed.api.v0.ComputedUserset.Object} */ (reader.readEnum());
      msg.setObject(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRelation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.ComputedUserset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.ComputedUserset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.ComputedUserset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.ComputedUserset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObject();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getRelation();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.authzed.api.v0.ComputedUserset.Object = {
  TUPLE_OBJECT: 0,
  TUPLE_USERSET_OBJECT: 1
};

/**
 * optional Object object = 1;
 * @return {!proto.authzed.api.v0.ComputedUserset.Object}
 */
proto.authzed.api.v0.ComputedUserset.prototype.getObject = function() {
  return /** @type {!proto.authzed.api.v0.ComputedUserset.Object} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.authzed.api.v0.ComputedUserset.Object} value
 * @return {!proto.authzed.api.v0.ComputedUserset} returns this
 */
proto.authzed.api.v0.ComputedUserset.prototype.setObject = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string relation = 2;
 * @return {string}
 */
proto.authzed.api.v0.ComputedUserset.prototype.getRelation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.authzed.api.v0.ComputedUserset} returns this
 */
proto.authzed.api.v0.ComputedUserset.prototype.setRelation = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.authzed.api.v0);
