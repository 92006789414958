// source: authzed/api/v0/developer.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var authzed_api_v0_core_pb = require('../../../authzed/api/v0/core_pb.js');
goog.object.extend(proto, authzed_api_v0_core_pb);
var authzed_api_v0_namespace_pb = require('../../../authzed/api/v0/namespace_pb.js');
goog.object.extend(proto, authzed_api_v0_namespace_pb);
goog.exportSymbol('proto.authzed.api.v0.DeveloperError', null, global);
goog.exportSymbol('proto.authzed.api.v0.DeveloperError.ErrorKind', null, global);
goog.exportSymbol('proto.authzed.api.v0.DeveloperError.Source', null, global);
goog.exportSymbol('proto.authzed.api.v0.EditCheckRequest', null, global);
goog.exportSymbol('proto.authzed.api.v0.EditCheckResponse', null, global);
goog.exportSymbol('proto.authzed.api.v0.EditCheckResult', null, global);
goog.exportSymbol('proto.authzed.api.v0.FormatSchemaRequest', null, global);
goog.exportSymbol('proto.authzed.api.v0.FormatSchemaResponse', null, global);
goog.exportSymbol('proto.authzed.api.v0.LookupShareRequest', null, global);
goog.exportSymbol('proto.authzed.api.v0.LookupShareResponse', null, global);
goog.exportSymbol('proto.authzed.api.v0.LookupShareResponse.LookupStatus', null, global);
goog.exportSymbol('proto.authzed.api.v0.RequestContext', null, global);
goog.exportSymbol('proto.authzed.api.v0.ShareRequest', null, global);
goog.exportSymbol('proto.authzed.api.v0.ShareResponse', null, global);
goog.exportSymbol('proto.authzed.api.v0.UpgradeSchemaRequest', null, global);
goog.exportSymbol('proto.authzed.api.v0.UpgradeSchemaResponse', null, global);
goog.exportSymbol('proto.authzed.api.v0.ValidateRequest', null, global);
goog.exportSymbol('proto.authzed.api.v0.ValidateResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.FormatSchemaRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authzed.api.v0.FormatSchemaRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.FormatSchemaRequest.displayName = 'proto.authzed.api.v0.FormatSchemaRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.FormatSchemaResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authzed.api.v0.FormatSchemaResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.FormatSchemaResponse.displayName = 'proto.authzed.api.v0.FormatSchemaResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.UpgradeSchemaRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.authzed.api.v0.UpgradeSchemaRequest.repeatedFields_, null);
};
goog.inherits(proto.authzed.api.v0.UpgradeSchemaRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.UpgradeSchemaRequest.displayName = 'proto.authzed.api.v0.UpgradeSchemaRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.UpgradeSchemaResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authzed.api.v0.UpgradeSchemaResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.UpgradeSchemaResponse.displayName = 'proto.authzed.api.v0.UpgradeSchemaResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.ShareRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authzed.api.v0.ShareRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.ShareRequest.displayName = 'proto.authzed.api.v0.ShareRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.ShareResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authzed.api.v0.ShareResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.ShareResponse.displayName = 'proto.authzed.api.v0.ShareResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.LookupShareRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authzed.api.v0.LookupShareRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.LookupShareRequest.displayName = 'proto.authzed.api.v0.LookupShareRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.LookupShareResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authzed.api.v0.LookupShareResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.LookupShareResponse.displayName = 'proto.authzed.api.v0.LookupShareResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.RequestContext = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.authzed.api.v0.RequestContext.repeatedFields_, null);
};
goog.inherits(proto.authzed.api.v0.RequestContext, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.RequestContext.displayName = 'proto.authzed.api.v0.RequestContext';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.EditCheckRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.authzed.api.v0.EditCheckRequest.repeatedFields_, null);
};
goog.inherits(proto.authzed.api.v0.EditCheckRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.EditCheckRequest.displayName = 'proto.authzed.api.v0.EditCheckRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.EditCheckResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authzed.api.v0.EditCheckResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.EditCheckResult.displayName = 'proto.authzed.api.v0.EditCheckResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.EditCheckResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.authzed.api.v0.EditCheckResponse.repeatedFields_, null);
};
goog.inherits(proto.authzed.api.v0.EditCheckResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.EditCheckResponse.displayName = 'proto.authzed.api.v0.EditCheckResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.ValidateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authzed.api.v0.ValidateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.ValidateRequest.displayName = 'proto.authzed.api.v0.ValidateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.ValidateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.authzed.api.v0.ValidateResponse.repeatedFields_, null);
};
goog.inherits(proto.authzed.api.v0.ValidateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.ValidateResponse.displayName = 'proto.authzed.api.v0.ValidateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authzed.api.v0.DeveloperError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.authzed.api.v0.DeveloperError.repeatedFields_, null);
};
goog.inherits(proto.authzed.api.v0.DeveloperError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authzed.api.v0.DeveloperError.displayName = 'proto.authzed.api.v0.DeveloperError';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.FormatSchemaRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.FormatSchemaRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.FormatSchemaRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.FormatSchemaRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    schema: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.FormatSchemaRequest}
 */
proto.authzed.api.v0.FormatSchemaRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.FormatSchemaRequest;
  return proto.authzed.api.v0.FormatSchemaRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.FormatSchemaRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.FormatSchemaRequest}
 */
proto.authzed.api.v0.FormatSchemaRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchema(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.FormatSchemaRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.FormatSchemaRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.FormatSchemaRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.FormatSchemaRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSchema();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string schema = 1;
 * @return {string}
 */
proto.authzed.api.v0.FormatSchemaRequest.prototype.getSchema = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.authzed.api.v0.FormatSchemaRequest} returns this
 */
proto.authzed.api.v0.FormatSchemaRequest.prototype.setSchema = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.FormatSchemaResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.FormatSchemaResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.FormatSchemaResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.FormatSchemaResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.authzed.api.v0.DeveloperError.toObject(includeInstance, f),
    formattedSchema: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.FormatSchemaResponse}
 */
proto.authzed.api.v0.FormatSchemaResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.FormatSchemaResponse;
  return proto.authzed.api.v0.FormatSchemaResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.FormatSchemaResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.FormatSchemaResponse}
 */
proto.authzed.api.v0.FormatSchemaResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.authzed.api.v0.DeveloperError;
      reader.readMessage(value,proto.authzed.api.v0.DeveloperError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormattedSchema(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.FormatSchemaResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.FormatSchemaResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.FormatSchemaResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.FormatSchemaResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.authzed.api.v0.DeveloperError.serializeBinaryToWriter
    );
  }
  f = message.getFormattedSchema();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional DeveloperError error = 1;
 * @return {?proto.authzed.api.v0.DeveloperError}
 */
proto.authzed.api.v0.FormatSchemaResponse.prototype.getError = function() {
  return /** @type{?proto.authzed.api.v0.DeveloperError} */ (
    jspb.Message.getWrapperField(this, proto.authzed.api.v0.DeveloperError, 1));
};


/**
 * @param {?proto.authzed.api.v0.DeveloperError|undefined} value
 * @return {!proto.authzed.api.v0.FormatSchemaResponse} returns this
*/
proto.authzed.api.v0.FormatSchemaResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authzed.api.v0.FormatSchemaResponse} returns this
 */
proto.authzed.api.v0.FormatSchemaResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authzed.api.v0.FormatSchemaResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string formatted_schema = 2;
 * @return {string}
 */
proto.authzed.api.v0.FormatSchemaResponse.prototype.getFormattedSchema = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.authzed.api.v0.FormatSchemaResponse} returns this
 */
proto.authzed.api.v0.FormatSchemaResponse.prototype.setFormattedSchema = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.authzed.api.v0.UpgradeSchemaRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.UpgradeSchemaRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.UpgradeSchemaRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.UpgradeSchemaRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.UpgradeSchemaRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    namespaceConfigsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.UpgradeSchemaRequest}
 */
proto.authzed.api.v0.UpgradeSchemaRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.UpgradeSchemaRequest;
  return proto.authzed.api.v0.UpgradeSchemaRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.UpgradeSchemaRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.UpgradeSchemaRequest}
 */
proto.authzed.api.v0.UpgradeSchemaRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addNamespaceConfigs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.UpgradeSchemaRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.UpgradeSchemaRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.UpgradeSchemaRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.UpgradeSchemaRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNamespaceConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string namespace_configs = 1;
 * @return {!Array<string>}
 */
proto.authzed.api.v0.UpgradeSchemaRequest.prototype.getNamespaceConfigsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.authzed.api.v0.UpgradeSchemaRequest} returns this
 */
proto.authzed.api.v0.UpgradeSchemaRequest.prototype.setNamespaceConfigsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.authzed.api.v0.UpgradeSchemaRequest} returns this
 */
proto.authzed.api.v0.UpgradeSchemaRequest.prototype.addNamespaceConfigs = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.authzed.api.v0.UpgradeSchemaRequest} returns this
 */
proto.authzed.api.v0.UpgradeSchemaRequest.prototype.clearNamespaceConfigsList = function() {
  return this.setNamespaceConfigsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.UpgradeSchemaResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.UpgradeSchemaResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.UpgradeSchemaResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.UpgradeSchemaResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto.authzed.api.v0.DeveloperError.toObject(includeInstance, f),
    upgradedSchema: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.UpgradeSchemaResponse}
 */
proto.authzed.api.v0.UpgradeSchemaResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.UpgradeSchemaResponse;
  return proto.authzed.api.v0.UpgradeSchemaResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.UpgradeSchemaResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.UpgradeSchemaResponse}
 */
proto.authzed.api.v0.UpgradeSchemaResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.authzed.api.v0.DeveloperError;
      reader.readMessage(value,proto.authzed.api.v0.DeveloperError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpgradedSchema(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.UpgradeSchemaResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.UpgradeSchemaResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.UpgradeSchemaResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.UpgradeSchemaResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.authzed.api.v0.DeveloperError.serializeBinaryToWriter
    );
  }
  f = message.getUpgradedSchema();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional DeveloperError error = 1;
 * @return {?proto.authzed.api.v0.DeveloperError}
 */
proto.authzed.api.v0.UpgradeSchemaResponse.prototype.getError = function() {
  return /** @type{?proto.authzed.api.v0.DeveloperError} */ (
    jspb.Message.getWrapperField(this, proto.authzed.api.v0.DeveloperError, 1));
};


/**
 * @param {?proto.authzed.api.v0.DeveloperError|undefined} value
 * @return {!proto.authzed.api.v0.UpgradeSchemaResponse} returns this
*/
proto.authzed.api.v0.UpgradeSchemaResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authzed.api.v0.UpgradeSchemaResponse} returns this
 */
proto.authzed.api.v0.UpgradeSchemaResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authzed.api.v0.UpgradeSchemaResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string upgraded_schema = 2;
 * @return {string}
 */
proto.authzed.api.v0.UpgradeSchemaResponse.prototype.getUpgradedSchema = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.authzed.api.v0.UpgradeSchemaResponse} returns this
 */
proto.authzed.api.v0.UpgradeSchemaResponse.prototype.setUpgradedSchema = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.ShareRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.ShareRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.ShareRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.ShareRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    schema: jspb.Message.getFieldWithDefault(msg, 1, ""),
    relationshipsYaml: jspb.Message.getFieldWithDefault(msg, 2, ""),
    validationYaml: jspb.Message.getFieldWithDefault(msg, 3, ""),
    assertionsYaml: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.ShareRequest}
 */
proto.authzed.api.v0.ShareRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.ShareRequest;
  return proto.authzed.api.v0.ShareRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.ShareRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.ShareRequest}
 */
proto.authzed.api.v0.ShareRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchema(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRelationshipsYaml(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidationYaml(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssertionsYaml(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.ShareRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.ShareRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.ShareRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.ShareRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSchema();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRelationshipsYaml();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValidationYaml();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAssertionsYaml();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string schema = 1;
 * @return {string}
 */
proto.authzed.api.v0.ShareRequest.prototype.getSchema = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.authzed.api.v0.ShareRequest} returns this
 */
proto.authzed.api.v0.ShareRequest.prototype.setSchema = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string relationships_yaml = 2;
 * @return {string}
 */
proto.authzed.api.v0.ShareRequest.prototype.getRelationshipsYaml = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.authzed.api.v0.ShareRequest} returns this
 */
proto.authzed.api.v0.ShareRequest.prototype.setRelationshipsYaml = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string validation_yaml = 3;
 * @return {string}
 */
proto.authzed.api.v0.ShareRequest.prototype.getValidationYaml = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.authzed.api.v0.ShareRequest} returns this
 */
proto.authzed.api.v0.ShareRequest.prototype.setValidationYaml = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string assertions_yaml = 4;
 * @return {string}
 */
proto.authzed.api.v0.ShareRequest.prototype.getAssertionsYaml = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.authzed.api.v0.ShareRequest} returns this
 */
proto.authzed.api.v0.ShareRequest.prototype.setAssertionsYaml = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.ShareResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.ShareResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.ShareResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.ShareResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    shareReference: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.ShareResponse}
 */
proto.authzed.api.v0.ShareResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.ShareResponse;
  return proto.authzed.api.v0.ShareResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.ShareResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.ShareResponse}
 */
proto.authzed.api.v0.ShareResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShareReference(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.ShareResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.ShareResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.ShareResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.ShareResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShareReference();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string share_reference = 1;
 * @return {string}
 */
proto.authzed.api.v0.ShareResponse.prototype.getShareReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.authzed.api.v0.ShareResponse} returns this
 */
proto.authzed.api.v0.ShareResponse.prototype.setShareReference = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.LookupShareRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.LookupShareRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.LookupShareRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.LookupShareRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    shareReference: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.LookupShareRequest}
 */
proto.authzed.api.v0.LookupShareRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.LookupShareRequest;
  return proto.authzed.api.v0.LookupShareRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.LookupShareRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.LookupShareRequest}
 */
proto.authzed.api.v0.LookupShareRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShareReference(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.LookupShareRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.LookupShareRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.LookupShareRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.LookupShareRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShareReference();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string share_reference = 1;
 * @return {string}
 */
proto.authzed.api.v0.LookupShareRequest.prototype.getShareReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.authzed.api.v0.LookupShareRequest} returns this
 */
proto.authzed.api.v0.LookupShareRequest.prototype.setShareReference = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.LookupShareResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.LookupShareResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.LookupShareResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.LookupShareResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    schema: jspb.Message.getFieldWithDefault(msg, 2, ""),
    relationshipsYaml: jspb.Message.getFieldWithDefault(msg, 3, ""),
    validationYaml: jspb.Message.getFieldWithDefault(msg, 4, ""),
    assertionsYaml: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.LookupShareResponse}
 */
proto.authzed.api.v0.LookupShareResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.LookupShareResponse;
  return proto.authzed.api.v0.LookupShareResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.LookupShareResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.LookupShareResponse}
 */
proto.authzed.api.v0.LookupShareResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.authzed.api.v0.LookupShareResponse.LookupStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchema(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRelationshipsYaml(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidationYaml(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssertionsYaml(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.LookupShareResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.LookupShareResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.LookupShareResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.LookupShareResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSchema();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRelationshipsYaml();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getValidationYaml();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAssertionsYaml();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.authzed.api.v0.LookupShareResponse.LookupStatus = {
  UNKNOWN_REFERENCE: 0,
  FAILED_TO_LOOKUP: 1,
  VALID_REFERENCE: 2,
  UPGRADED_REFERENCE: 3
};

/**
 * optional LookupStatus status = 1;
 * @return {!proto.authzed.api.v0.LookupShareResponse.LookupStatus}
 */
proto.authzed.api.v0.LookupShareResponse.prototype.getStatus = function() {
  return /** @type {!proto.authzed.api.v0.LookupShareResponse.LookupStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.authzed.api.v0.LookupShareResponse.LookupStatus} value
 * @return {!proto.authzed.api.v0.LookupShareResponse} returns this
 */
proto.authzed.api.v0.LookupShareResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string schema = 2;
 * @return {string}
 */
proto.authzed.api.v0.LookupShareResponse.prototype.getSchema = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.authzed.api.v0.LookupShareResponse} returns this
 */
proto.authzed.api.v0.LookupShareResponse.prototype.setSchema = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string relationships_yaml = 3;
 * @return {string}
 */
proto.authzed.api.v0.LookupShareResponse.prototype.getRelationshipsYaml = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.authzed.api.v0.LookupShareResponse} returns this
 */
proto.authzed.api.v0.LookupShareResponse.prototype.setRelationshipsYaml = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string validation_yaml = 4;
 * @return {string}
 */
proto.authzed.api.v0.LookupShareResponse.prototype.getValidationYaml = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.authzed.api.v0.LookupShareResponse} returns this
 */
proto.authzed.api.v0.LookupShareResponse.prototype.setValidationYaml = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string assertions_yaml = 5;
 * @return {string}
 */
proto.authzed.api.v0.LookupShareResponse.prototype.getAssertionsYaml = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.authzed.api.v0.LookupShareResponse} returns this
 */
proto.authzed.api.v0.LookupShareResponse.prototype.setAssertionsYaml = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.authzed.api.v0.RequestContext.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.RequestContext.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.RequestContext.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.RequestContext} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.RequestContext.toObject = function(includeInstance, msg) {
  var f, obj = {
    schema: jspb.Message.getFieldWithDefault(msg, 1, ""),
    relationshipsList: jspb.Message.toObjectList(msg.getRelationshipsList(),
    authzed_api_v0_core_pb.RelationTuple.toObject, includeInstance),
    legacyNsConfigsList: jspb.Message.toObjectList(msg.getLegacyNsConfigsList(),
    authzed_api_v0_namespace_pb.NamespaceDefinition.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.RequestContext}
 */
proto.authzed.api.v0.RequestContext.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.RequestContext;
  return proto.authzed.api.v0.RequestContext.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.RequestContext} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.RequestContext}
 */
proto.authzed.api.v0.RequestContext.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchema(value);
      break;
    case 2:
      var value = new authzed_api_v0_core_pb.RelationTuple;
      reader.readMessage(value,authzed_api_v0_core_pb.RelationTuple.deserializeBinaryFromReader);
      msg.addRelationships(value);
      break;
    case 3:
      var value = new authzed_api_v0_namespace_pb.NamespaceDefinition;
      reader.readMessage(value,authzed_api_v0_namespace_pb.NamespaceDefinition.deserializeBinaryFromReader);
      msg.addLegacyNsConfigs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.RequestContext.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.RequestContext.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.RequestContext} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.RequestContext.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSchema();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRelationshipsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      authzed_api_v0_core_pb.RelationTuple.serializeBinaryToWriter
    );
  }
  f = message.getLegacyNsConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      authzed_api_v0_namespace_pb.NamespaceDefinition.serializeBinaryToWriter
    );
  }
};


/**
 * optional string schema = 1;
 * @return {string}
 */
proto.authzed.api.v0.RequestContext.prototype.getSchema = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.authzed.api.v0.RequestContext} returns this
 */
proto.authzed.api.v0.RequestContext.prototype.setSchema = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated RelationTuple relationships = 2;
 * @return {!Array<!proto.authzed.api.v0.RelationTuple>}
 */
proto.authzed.api.v0.RequestContext.prototype.getRelationshipsList = function() {
  return /** @type{!Array<!proto.authzed.api.v0.RelationTuple>} */ (
    jspb.Message.getRepeatedWrapperField(this, authzed_api_v0_core_pb.RelationTuple, 2));
};


/**
 * @param {!Array<!proto.authzed.api.v0.RelationTuple>} value
 * @return {!proto.authzed.api.v0.RequestContext} returns this
*/
proto.authzed.api.v0.RequestContext.prototype.setRelationshipsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.authzed.api.v0.RelationTuple=} opt_value
 * @param {number=} opt_index
 * @return {!proto.authzed.api.v0.RelationTuple}
 */
proto.authzed.api.v0.RequestContext.prototype.addRelationships = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.authzed.api.v0.RelationTuple, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.authzed.api.v0.RequestContext} returns this
 */
proto.authzed.api.v0.RequestContext.prototype.clearRelationshipsList = function() {
  return this.setRelationshipsList([]);
};


/**
 * repeated NamespaceDefinition legacy_ns_configs = 3;
 * @return {!Array<!proto.authzed.api.v0.NamespaceDefinition>}
 */
proto.authzed.api.v0.RequestContext.prototype.getLegacyNsConfigsList = function() {
  return /** @type{!Array<!proto.authzed.api.v0.NamespaceDefinition>} */ (
    jspb.Message.getRepeatedWrapperField(this, authzed_api_v0_namespace_pb.NamespaceDefinition, 3));
};


/**
 * @param {!Array<!proto.authzed.api.v0.NamespaceDefinition>} value
 * @return {!proto.authzed.api.v0.RequestContext} returns this
*/
proto.authzed.api.v0.RequestContext.prototype.setLegacyNsConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.authzed.api.v0.NamespaceDefinition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.authzed.api.v0.NamespaceDefinition}
 */
proto.authzed.api.v0.RequestContext.prototype.addLegacyNsConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.authzed.api.v0.NamespaceDefinition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.authzed.api.v0.RequestContext} returns this
 */
proto.authzed.api.v0.RequestContext.prototype.clearLegacyNsConfigsList = function() {
  return this.setLegacyNsConfigsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.authzed.api.v0.EditCheckRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.EditCheckRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.EditCheckRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.EditCheckRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.EditCheckRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    context: (f = msg.getContext()) && proto.authzed.api.v0.RequestContext.toObject(includeInstance, f),
    checkRelationshipsList: jspb.Message.toObjectList(msg.getCheckRelationshipsList(),
    authzed_api_v0_core_pb.RelationTuple.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.EditCheckRequest}
 */
proto.authzed.api.v0.EditCheckRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.EditCheckRequest;
  return proto.authzed.api.v0.EditCheckRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.EditCheckRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.EditCheckRequest}
 */
proto.authzed.api.v0.EditCheckRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.authzed.api.v0.RequestContext;
      reader.readMessage(value,proto.authzed.api.v0.RequestContext.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new authzed_api_v0_core_pb.RelationTuple;
      reader.readMessage(value,authzed_api_v0_core_pb.RelationTuple.deserializeBinaryFromReader);
      msg.addCheckRelationships(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.EditCheckRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.EditCheckRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.EditCheckRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.EditCheckRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.authzed.api.v0.RequestContext.serializeBinaryToWriter
    );
  }
  f = message.getCheckRelationshipsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      authzed_api_v0_core_pb.RelationTuple.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestContext context = 1;
 * @return {?proto.authzed.api.v0.RequestContext}
 */
proto.authzed.api.v0.EditCheckRequest.prototype.getContext = function() {
  return /** @type{?proto.authzed.api.v0.RequestContext} */ (
    jspb.Message.getWrapperField(this, proto.authzed.api.v0.RequestContext, 1));
};


/**
 * @param {?proto.authzed.api.v0.RequestContext|undefined} value
 * @return {!proto.authzed.api.v0.EditCheckRequest} returns this
*/
proto.authzed.api.v0.EditCheckRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authzed.api.v0.EditCheckRequest} returns this
 */
proto.authzed.api.v0.EditCheckRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authzed.api.v0.EditCheckRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated RelationTuple check_relationships = 2;
 * @return {!Array<!proto.authzed.api.v0.RelationTuple>}
 */
proto.authzed.api.v0.EditCheckRequest.prototype.getCheckRelationshipsList = function() {
  return /** @type{!Array<!proto.authzed.api.v0.RelationTuple>} */ (
    jspb.Message.getRepeatedWrapperField(this, authzed_api_v0_core_pb.RelationTuple, 2));
};


/**
 * @param {!Array<!proto.authzed.api.v0.RelationTuple>} value
 * @return {!proto.authzed.api.v0.EditCheckRequest} returns this
*/
proto.authzed.api.v0.EditCheckRequest.prototype.setCheckRelationshipsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.authzed.api.v0.RelationTuple=} opt_value
 * @param {number=} opt_index
 * @return {!proto.authzed.api.v0.RelationTuple}
 */
proto.authzed.api.v0.EditCheckRequest.prototype.addCheckRelationships = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.authzed.api.v0.RelationTuple, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.authzed.api.v0.EditCheckRequest} returns this
 */
proto.authzed.api.v0.EditCheckRequest.prototype.clearCheckRelationshipsList = function() {
  return this.setCheckRelationshipsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.EditCheckResult.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.EditCheckResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.EditCheckResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.EditCheckResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    relationship: (f = msg.getRelationship()) && authzed_api_v0_core_pb.RelationTuple.toObject(includeInstance, f),
    isMember: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    error: (f = msg.getError()) && proto.authzed.api.v0.DeveloperError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.EditCheckResult}
 */
proto.authzed.api.v0.EditCheckResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.EditCheckResult;
  return proto.authzed.api.v0.EditCheckResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.EditCheckResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.EditCheckResult}
 */
proto.authzed.api.v0.EditCheckResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new authzed_api_v0_core_pb.RelationTuple;
      reader.readMessage(value,authzed_api_v0_core_pb.RelationTuple.deserializeBinaryFromReader);
      msg.setRelationship(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMember(value);
      break;
    case 3:
      var value = new proto.authzed.api.v0.DeveloperError;
      reader.readMessage(value,proto.authzed.api.v0.DeveloperError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.EditCheckResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.EditCheckResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.EditCheckResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.EditCheckResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRelationship();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      authzed_api_v0_core_pb.RelationTuple.serializeBinaryToWriter
    );
  }
  f = message.getIsMember();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.authzed.api.v0.DeveloperError.serializeBinaryToWriter
    );
  }
};


/**
 * optional RelationTuple relationship = 1;
 * @return {?proto.authzed.api.v0.RelationTuple}
 */
proto.authzed.api.v0.EditCheckResult.prototype.getRelationship = function() {
  return /** @type{?proto.authzed.api.v0.RelationTuple} */ (
    jspb.Message.getWrapperField(this, authzed_api_v0_core_pb.RelationTuple, 1));
};


/**
 * @param {?proto.authzed.api.v0.RelationTuple|undefined} value
 * @return {!proto.authzed.api.v0.EditCheckResult} returns this
*/
proto.authzed.api.v0.EditCheckResult.prototype.setRelationship = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authzed.api.v0.EditCheckResult} returns this
 */
proto.authzed.api.v0.EditCheckResult.prototype.clearRelationship = function() {
  return this.setRelationship(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authzed.api.v0.EditCheckResult.prototype.hasRelationship = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool is_member = 2;
 * @return {boolean}
 */
proto.authzed.api.v0.EditCheckResult.prototype.getIsMember = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.authzed.api.v0.EditCheckResult} returns this
 */
proto.authzed.api.v0.EditCheckResult.prototype.setIsMember = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional DeveloperError error = 3;
 * @return {?proto.authzed.api.v0.DeveloperError}
 */
proto.authzed.api.v0.EditCheckResult.prototype.getError = function() {
  return /** @type{?proto.authzed.api.v0.DeveloperError} */ (
    jspb.Message.getWrapperField(this, proto.authzed.api.v0.DeveloperError, 3));
};


/**
 * @param {?proto.authzed.api.v0.DeveloperError|undefined} value
 * @return {!proto.authzed.api.v0.EditCheckResult} returns this
*/
proto.authzed.api.v0.EditCheckResult.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authzed.api.v0.EditCheckResult} returns this
 */
proto.authzed.api.v0.EditCheckResult.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authzed.api.v0.EditCheckResult.prototype.hasError = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.authzed.api.v0.EditCheckResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.EditCheckResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.EditCheckResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.EditCheckResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.EditCheckResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestErrorsList: jspb.Message.toObjectList(msg.getRequestErrorsList(),
    proto.authzed.api.v0.DeveloperError.toObject, includeInstance),
    checkResultsList: jspb.Message.toObjectList(msg.getCheckResultsList(),
    proto.authzed.api.v0.EditCheckResult.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.EditCheckResponse}
 */
proto.authzed.api.v0.EditCheckResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.EditCheckResponse;
  return proto.authzed.api.v0.EditCheckResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.EditCheckResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.EditCheckResponse}
 */
proto.authzed.api.v0.EditCheckResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.authzed.api.v0.DeveloperError;
      reader.readMessage(value,proto.authzed.api.v0.DeveloperError.deserializeBinaryFromReader);
      msg.addRequestErrors(value);
      break;
    case 2:
      var value = new proto.authzed.api.v0.EditCheckResult;
      reader.readMessage(value,proto.authzed.api.v0.EditCheckResult.deserializeBinaryFromReader);
      msg.addCheckResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.EditCheckResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.EditCheckResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.EditCheckResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.EditCheckResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestErrorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.authzed.api.v0.DeveloperError.serializeBinaryToWriter
    );
  }
  f = message.getCheckResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.authzed.api.v0.EditCheckResult.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DeveloperError request_errors = 1;
 * @return {!Array<!proto.authzed.api.v0.DeveloperError>}
 */
proto.authzed.api.v0.EditCheckResponse.prototype.getRequestErrorsList = function() {
  return /** @type{!Array<!proto.authzed.api.v0.DeveloperError>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.authzed.api.v0.DeveloperError, 1));
};


/**
 * @param {!Array<!proto.authzed.api.v0.DeveloperError>} value
 * @return {!proto.authzed.api.v0.EditCheckResponse} returns this
*/
proto.authzed.api.v0.EditCheckResponse.prototype.setRequestErrorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.authzed.api.v0.DeveloperError=} opt_value
 * @param {number=} opt_index
 * @return {!proto.authzed.api.v0.DeveloperError}
 */
proto.authzed.api.v0.EditCheckResponse.prototype.addRequestErrors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.authzed.api.v0.DeveloperError, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.authzed.api.v0.EditCheckResponse} returns this
 */
proto.authzed.api.v0.EditCheckResponse.prototype.clearRequestErrorsList = function() {
  return this.setRequestErrorsList([]);
};


/**
 * repeated EditCheckResult check_results = 2;
 * @return {!Array<!proto.authzed.api.v0.EditCheckResult>}
 */
proto.authzed.api.v0.EditCheckResponse.prototype.getCheckResultsList = function() {
  return /** @type{!Array<!proto.authzed.api.v0.EditCheckResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.authzed.api.v0.EditCheckResult, 2));
};


/**
 * @param {!Array<!proto.authzed.api.v0.EditCheckResult>} value
 * @return {!proto.authzed.api.v0.EditCheckResponse} returns this
*/
proto.authzed.api.v0.EditCheckResponse.prototype.setCheckResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.authzed.api.v0.EditCheckResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.authzed.api.v0.EditCheckResult}
 */
proto.authzed.api.v0.EditCheckResponse.prototype.addCheckResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.authzed.api.v0.EditCheckResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.authzed.api.v0.EditCheckResponse} returns this
 */
proto.authzed.api.v0.EditCheckResponse.prototype.clearCheckResultsList = function() {
  return this.setCheckResultsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.ValidateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.ValidateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.ValidateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.ValidateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    context: (f = msg.getContext()) && proto.authzed.api.v0.RequestContext.toObject(includeInstance, f),
    validationYaml: jspb.Message.getFieldWithDefault(msg, 3, ""),
    updateValidationYaml: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    assertionsYaml: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.ValidateRequest}
 */
proto.authzed.api.v0.ValidateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.ValidateRequest;
  return proto.authzed.api.v0.ValidateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.ValidateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.ValidateRequest}
 */
proto.authzed.api.v0.ValidateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.authzed.api.v0.RequestContext;
      reader.readMessage(value,proto.authzed.api.v0.RequestContext.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidationYaml(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUpdateValidationYaml(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssertionsYaml(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.ValidateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.ValidateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.ValidateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.ValidateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.authzed.api.v0.RequestContext.serializeBinaryToWriter
    );
  }
  f = message.getValidationYaml();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUpdateValidationYaml();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getAssertionsYaml();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional RequestContext context = 1;
 * @return {?proto.authzed.api.v0.RequestContext}
 */
proto.authzed.api.v0.ValidateRequest.prototype.getContext = function() {
  return /** @type{?proto.authzed.api.v0.RequestContext} */ (
    jspb.Message.getWrapperField(this, proto.authzed.api.v0.RequestContext, 1));
};


/**
 * @param {?proto.authzed.api.v0.RequestContext|undefined} value
 * @return {!proto.authzed.api.v0.ValidateRequest} returns this
*/
proto.authzed.api.v0.ValidateRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authzed.api.v0.ValidateRequest} returns this
 */
proto.authzed.api.v0.ValidateRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authzed.api.v0.ValidateRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string validation_yaml = 3;
 * @return {string}
 */
proto.authzed.api.v0.ValidateRequest.prototype.getValidationYaml = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.authzed.api.v0.ValidateRequest} returns this
 */
proto.authzed.api.v0.ValidateRequest.prototype.setValidationYaml = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool update_validation_yaml = 4;
 * @return {boolean}
 */
proto.authzed.api.v0.ValidateRequest.prototype.getUpdateValidationYaml = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.authzed.api.v0.ValidateRequest} returns this
 */
proto.authzed.api.v0.ValidateRequest.prototype.setUpdateValidationYaml = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string assertions_yaml = 5;
 * @return {string}
 */
proto.authzed.api.v0.ValidateRequest.prototype.getAssertionsYaml = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.authzed.api.v0.ValidateRequest} returns this
 */
proto.authzed.api.v0.ValidateRequest.prototype.setAssertionsYaml = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.authzed.api.v0.ValidateResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.ValidateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.ValidateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.ValidateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.ValidateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestErrorsList: jspb.Message.toObjectList(msg.getRequestErrorsList(),
    proto.authzed.api.v0.DeveloperError.toObject, includeInstance),
    validationErrorsList: jspb.Message.toObjectList(msg.getValidationErrorsList(),
    proto.authzed.api.v0.DeveloperError.toObject, includeInstance),
    updatedValidationYaml: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.ValidateResponse}
 */
proto.authzed.api.v0.ValidateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.ValidateResponse;
  return proto.authzed.api.v0.ValidateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.ValidateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.ValidateResponse}
 */
proto.authzed.api.v0.ValidateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.authzed.api.v0.DeveloperError;
      reader.readMessage(value,proto.authzed.api.v0.DeveloperError.deserializeBinaryFromReader);
      msg.addRequestErrors(value);
      break;
    case 2:
      var value = new proto.authzed.api.v0.DeveloperError;
      reader.readMessage(value,proto.authzed.api.v0.DeveloperError.deserializeBinaryFromReader);
      msg.addValidationErrors(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedValidationYaml(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.ValidateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.ValidateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.ValidateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.ValidateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestErrorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.authzed.api.v0.DeveloperError.serializeBinaryToWriter
    );
  }
  f = message.getValidationErrorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.authzed.api.v0.DeveloperError.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedValidationYaml();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated DeveloperError request_errors = 1;
 * @return {!Array<!proto.authzed.api.v0.DeveloperError>}
 */
proto.authzed.api.v0.ValidateResponse.prototype.getRequestErrorsList = function() {
  return /** @type{!Array<!proto.authzed.api.v0.DeveloperError>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.authzed.api.v0.DeveloperError, 1));
};


/**
 * @param {!Array<!proto.authzed.api.v0.DeveloperError>} value
 * @return {!proto.authzed.api.v0.ValidateResponse} returns this
*/
proto.authzed.api.v0.ValidateResponse.prototype.setRequestErrorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.authzed.api.v0.DeveloperError=} opt_value
 * @param {number=} opt_index
 * @return {!proto.authzed.api.v0.DeveloperError}
 */
proto.authzed.api.v0.ValidateResponse.prototype.addRequestErrors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.authzed.api.v0.DeveloperError, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.authzed.api.v0.ValidateResponse} returns this
 */
proto.authzed.api.v0.ValidateResponse.prototype.clearRequestErrorsList = function() {
  return this.setRequestErrorsList([]);
};


/**
 * repeated DeveloperError validation_errors = 2;
 * @return {!Array<!proto.authzed.api.v0.DeveloperError>}
 */
proto.authzed.api.v0.ValidateResponse.prototype.getValidationErrorsList = function() {
  return /** @type{!Array<!proto.authzed.api.v0.DeveloperError>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.authzed.api.v0.DeveloperError, 2));
};


/**
 * @param {!Array<!proto.authzed.api.v0.DeveloperError>} value
 * @return {!proto.authzed.api.v0.ValidateResponse} returns this
*/
proto.authzed.api.v0.ValidateResponse.prototype.setValidationErrorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.authzed.api.v0.DeveloperError=} opt_value
 * @param {number=} opt_index
 * @return {!proto.authzed.api.v0.DeveloperError}
 */
proto.authzed.api.v0.ValidateResponse.prototype.addValidationErrors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.authzed.api.v0.DeveloperError, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.authzed.api.v0.ValidateResponse} returns this
 */
proto.authzed.api.v0.ValidateResponse.prototype.clearValidationErrorsList = function() {
  return this.setValidationErrorsList([]);
};


/**
 * optional string updated_validation_yaml = 3;
 * @return {string}
 */
proto.authzed.api.v0.ValidateResponse.prototype.getUpdatedValidationYaml = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.authzed.api.v0.ValidateResponse} returns this
 */
proto.authzed.api.v0.ValidateResponse.prototype.setUpdatedValidationYaml = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.authzed.api.v0.DeveloperError.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authzed.api.v0.DeveloperError.prototype.toObject = function(opt_includeInstance) {
  return proto.authzed.api.v0.DeveloperError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authzed.api.v0.DeveloperError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.DeveloperError.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    line: jspb.Message.getFieldWithDefault(msg, 2, 0),
    column: jspb.Message.getFieldWithDefault(msg, 3, 0),
    source: jspb.Message.getFieldWithDefault(msg, 4, 0),
    kind: jspb.Message.getFieldWithDefault(msg, 5, 0),
    pathList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    context: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authzed.api.v0.DeveloperError}
 */
proto.authzed.api.v0.DeveloperError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authzed.api.v0.DeveloperError;
  return proto.authzed.api.v0.DeveloperError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authzed.api.v0.DeveloperError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authzed.api.v0.DeveloperError}
 */
proto.authzed.api.v0.DeveloperError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLine(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setColumn(value);
      break;
    case 4:
      var value = /** @type {!proto.authzed.api.v0.DeveloperError.Source} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 5:
      var value = /** @type {!proto.authzed.api.v0.DeveloperError.ErrorKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addPath(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setContext(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authzed.api.v0.DeveloperError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authzed.api.v0.DeveloperError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authzed.api.v0.DeveloperError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authzed.api.v0.DeveloperError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLine();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getColumn();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getPathList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getContext();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.authzed.api.v0.DeveloperError.Source = {
  UNKNOWN_SOURCE: 0,
  SCHEMA: 1,
  RELATIONSHIP: 2,
  VALIDATION_YAML: 3,
  CHECK_WATCH: 4,
  ASSERTION: 5
};

/**
 * @enum {number}
 */
proto.authzed.api.v0.DeveloperError.ErrorKind = {
  UNKNOWN_KIND: 0,
  PARSE_ERROR: 1,
  SCHEMA_ISSUE: 2,
  DUPLICATE_RELATIONSHIP: 3,
  MISSING_EXPECTED_RELATIONSHIP: 4,
  EXTRA_RELATIONSHIP_FOUND: 5,
  UNKNOWN_OBJECT_TYPE: 6,
  UNKNOWN_RELATION: 7,
  MAXIMUM_RECURSION: 8,
  ASSERTION_FAILED: 9
};

/**
 * optional string message = 1;
 * @return {string}
 */
proto.authzed.api.v0.DeveloperError.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.authzed.api.v0.DeveloperError} returns this
 */
proto.authzed.api.v0.DeveloperError.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 line = 2;
 * @return {number}
 */
proto.authzed.api.v0.DeveloperError.prototype.getLine = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.authzed.api.v0.DeveloperError} returns this
 */
proto.authzed.api.v0.DeveloperError.prototype.setLine = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 column = 3;
 * @return {number}
 */
proto.authzed.api.v0.DeveloperError.prototype.getColumn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.authzed.api.v0.DeveloperError} returns this
 */
proto.authzed.api.v0.DeveloperError.prototype.setColumn = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Source source = 4;
 * @return {!proto.authzed.api.v0.DeveloperError.Source}
 */
proto.authzed.api.v0.DeveloperError.prototype.getSource = function() {
  return /** @type {!proto.authzed.api.v0.DeveloperError.Source} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.authzed.api.v0.DeveloperError.Source} value
 * @return {!proto.authzed.api.v0.DeveloperError} returns this
 */
proto.authzed.api.v0.DeveloperError.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional ErrorKind kind = 5;
 * @return {!proto.authzed.api.v0.DeveloperError.ErrorKind}
 */
proto.authzed.api.v0.DeveloperError.prototype.getKind = function() {
  return /** @type {!proto.authzed.api.v0.DeveloperError.ErrorKind} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.authzed.api.v0.DeveloperError.ErrorKind} value
 * @return {!proto.authzed.api.v0.DeveloperError} returns this
 */
proto.authzed.api.v0.DeveloperError.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * repeated string path = 6;
 * @return {!Array<string>}
 */
proto.authzed.api.v0.DeveloperError.prototype.getPathList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.authzed.api.v0.DeveloperError} returns this
 */
proto.authzed.api.v0.DeveloperError.prototype.setPathList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.authzed.api.v0.DeveloperError} returns this
 */
proto.authzed.api.v0.DeveloperError.prototype.addPath = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.authzed.api.v0.DeveloperError} returns this
 */
proto.authzed.api.v0.DeveloperError.prototype.clearPathList = function() {
  return this.setPathList([]);
};


/**
 * optional string context = 7;
 * @return {string}
 */
proto.authzed.api.v0.DeveloperError.prototype.getContext = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.authzed.api.v0.DeveloperError} returns this
 */
proto.authzed.api.v0.DeveloperError.prototype.setContext = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


goog.object.extend(exports, proto.authzed.api.v0);
